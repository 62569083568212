import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCbdJpIORE0DfGLGOQ3Ly4-cGlYMclb010",
  authDomain: "shameo-5449e.firebaseapp.com",
  projectId: "shameo-5449e",
  storageBucket: "shameo-5449e.appspot.com",
  messagingSenderId: "358709211097",
  appId: "1:358709211097:web:807110299132e16a2ef3c8",
  measurementId: "G-4DSSMZ0HFL",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };