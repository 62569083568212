import React, { useContext, useEffect, useRef, useState } from "react";
import "./Header.scss";
import menuicon from "../../Assets/Images/menuicon.png";
import logo from "../../Assets/Images/logo.png";
import headeruser from "../../Assets/Images/headeruser.png";
import useredit from "../../Assets/Images/useredit.png";
import { NavLink, useNavigate } from "react-router-dom";
import {
  ApiKeyIcon,
  Dashboard,
  LinkIcon,
  Pricing,
  Role,
  Upload,
  VoiceAssistance,
  WidgetIcon,
} from "../../Assets/icons/Icons";
import { UserContext } from "../../store/UserDetailsStore";
import { useTranslation } from "react-i18next";
import { signOut } from "firebase/auth";
import { auth } from "../../Config/firebaseConfig";

const Header = () => {
  const { t, i18n } = useTranslation();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { isLoginUserDetails, getLoginUserDetails } = useContext(UserContext);
  const [showLogout, setShowLogout] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "english"
  );
  const menuRef = useRef(null);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const saveLanguage = (lang) => {
    localStorage.setItem("selectedLanguage", lang);
    setSelectedLanguage(lang === "english" ? "English" : "中文");
    i18n.changeLanguage(lang);
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log("User signed out");
      })
      .catch((error) => {
        console.error("Sign-out error:", error);
      });
    const selectedLanguage = localStorage.getItem("selectedLanguage");
    localStorage.clear();
    if (selectedLanguage) {
      localStorage.setItem("selectedLanguage", selectedLanguage);
    }
    navigate("/login");
  };

  const handleDashboard = () => {
    navigate("/role");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getLoginUserDetails();
  }, []);

  useEffect(() => {
    setSelectedLanguage(
      localStorage.getItem("selectedLanguage") === "english"
        ? "English"
        : "中文"
    );
  }, []);

  useEffect(() => {
    if (!isLoginUserDetails) {
      navigate("/login");
    }
  }, [isLoginUserDetails, navigate]);

  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="header2">
      <div className="header2_container">
        <div className="header2_section">
          <div className="header2_section_left">
            {/* <img src={menuicon} alt="menuicon" className="menu" /> */}
            <img
              src={logo}
              alt="logo"
              className="logo"
              onClick={handleDashboard}
            />
          </div>
          <div className="header2_section_center">
            <ul>
              <li>
                <NavLink to="/role" activeclassname="active">
                  <Role
                    color={
                      window.location.pathname === "/role" ? "#000" : "#6B6A6A"
                    }
                  />
                  {t("headerLinkOne")}
                </NavLink>
                {window.location.pathname === "/role" && (
                  <div className="navLinkWrapper"></div>
                )}
              </li>
              <li>
                <NavLink to="/upload" activeclassname="active">
                  <Upload
                    color={
                      window.location.pathname === "/upload"
                        ? "#000"
                        : "#6B6A6A"
                    }
                  />
                  {t("headerLinkTwo")}
                </NavLink>
                {window.location.pathname === "/upload" && (
                  <div className="navLinkWrapper"></div>
                )}
              </li>
              <li>
                <NavLink to="/voiceAssistance" activeclassname="active">
                  <VoiceAssistance
                    color={
                      window.location.pathname === "/voiceAssistance"
                        ? "#000"
                        : "#6B6A6A"
                    }
                  />
                  {t("headerLinkThree")}
                </NavLink>
                {window.location.pathname === "/voiceAssistance" && (
                  <div className="navLinkWrapper"></div>
                )}
              </li>
              {/* <li>
                <NavLink to="/widget" activeclassname="active">
                  <WidgetIcon
                    color={
                      window.location.pathname === "/widget"
                        ? "#000"
                        : "#6B6A6A"
                    }
                  />
                  {t("headerLinkSix")}
                </NavLink>
                {window.location.pathname === "/widget" && (
                  <div className="navLinkWrapper"></div>
                )}
              </li> */}
              {/* <li>
                <NavLink to="/api-key" activeclassname="active">
                  <ApiKeyIcon
                    color={
                      window.location.pathname === "/api-key"
                        ? "#000"
                        : "#6B6A6A"
                    }
                  />
                  {t("headerLinkSeven")}
                </NavLink>
                {window.location.pathname === "/api-key" && (
                  <div className="navLinkWrapper"></div>
                )}
              </li> */}
              <li>
                <NavLink to="/pricing" activeclassname="active">
                  <Pricing
                    color={
                      window.location.pathname === "/pricing"
                        ? "#000"
                        : "#6B6A6A"
                    }
                  />
                  {t("headerLinkFour")}
                </NavLink>
                {window.location.pathname === "/pricing" && (
                  <div className="navLinkWrapper"></div>
                )}
              </li>
            </ul>
          </div>
          <div className="header2_section_right">
            <div className="header2_section_right_select">
              <div
                className="header2_section_right_select_dropdown"
                onClick={toggleDropdown}
              >
                <p>{selectedLanguage}</p>
                <div
                  className={`dropdown-content ${showDropdown ? "show" : ""}`}
                >
                  <a href="#" onClick={() => saveLanguage("english")}>
                    English
                  </a>
                  <a href="#" onClick={() => saveLanguage("chinese")}>
                    中文
                  </a>
                </div>
              </div>
            </div>
            <div className="header2_section_right_free">
              <span>
                {capitalizeFirstLetter(
                  isLoginUserDetails?.subscriptionType ?? t("free")
                )}
              </span>
            </div>
            <div
              className="header2_section_right_user"
              onMouseEnter={() => setShowLogout(true)}
              onMouseLeave={() => setShowLogout(false)}
              onClick={() => setShowLogout(true)}
            >
              <img
                src={isLoginUserDetails?.profilePicture ?? headeruser}
                alt="user"
                className="user"
              />
              {/* <img src={useredit} alt="user" className="useredit" /> */}
              {showLogout && (
                <div className="logout_option">
                  <button onClick={handleLogout}>{t("logout")}</button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="header2_mobile-section">
          <div className="header2_mobile-section_left">
            <img
              src={menuicon}
              alt="menuicon"
              className="menu"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            />
            <img
              src={logo}
              alt="logo"
              className="logo"
              onClick={handleDashboard}
            />
          </div>
          {/* <div className="header_section_right_beta">
            <span>Beta</span>
          </div> */}
          <div className="header2_section_right_select">
            <div
              className="header2_section_right_select_dropdown"
              onClick={toggleDropdown}
            >
              <p>{selectedLanguage}</p>
              <div className={`dropdown-content ${showDropdown ? "show" : ""}`}>
                <a href="#" onClick={() => saveLanguage("english")}>
                  English
                </a>
                <a href="#" onClick={() => saveLanguage("chinese")}>
                  中文
                </a>
              </div>
            </div>
          </div>
          {isMenuOpen && (
            <div className="header2_mobile-section_sidebar" ref={menuRef}>
              <div className="header2_mobile-section_sidebar_top">
                <div
                  className="header2_mobile-section_sidebar_top_user"
                  onMouseEnter={() => setShowLogout(true)}
                  onMouseLeave={() => setShowLogout(false)}
                  onClick={() => setShowLogout(true)}
                >
                  <img
                    src={isLoginUserDetails?.profilePicture ?? headeruser}
                    alt="user"
                    className="user"
                  />
                  {/* <img src={useredit} alt="user" className="useredit" /> */}
                </div>
                <div className="header2_mobile_section_sidebar_top_username">
                  <h3>
                    {isLoginUserDetails ? isLoginUserDetails.fullName : "Guest"}
                  </h3>
                </div>
                <div className="header2_mobile-section_sidebar_top_free">
                  <span>
                    {capitalizeFirstLetter(
                      isLoginUserDetails?.subscriptionType ?? t("free")
                    )}
                  </span>
                </div>
              </div>
              <div className="header2_mobile-section_sidebar_center">
                <ul>
                  {/* <li onClick={() => setIsMenuOpen(false)}>
                    <NavLink to="/dashboard" activeclassname="active">
                      <Dashboard
                        color={
                          window.location.pathname === "/dashboard"
                            ? "#000"
                            : "#6B6A6A"
                        }
                        className="icons"
                      />
                      <h4>Dashboard</h4>
                    </NavLink>
                  </li> */}
                  <li onClick={() => setIsMenuOpen(false)}>
                    <NavLink to="/role" activeclassname="active">
                      <Role
                        color={
                          window.location.pathname === "/role"
                            ? "#000"
                            : "#6B6A6A"
                        }
                      />
                      <h4>{t("headerLinkOne")}</h4>
                    </NavLink>
                  </li>
                  <li onClick={() => setIsMenuOpen(false)}>
                    <NavLink to="/upload" activeclassname="active">
                      <Upload
                        color={
                          window.location.pathname === "/upload"
                            ? "#000"
                            : "#6B6A6A"
                        }
                      />
                      <h4>{t("headerLinkTwo")}</h4>
                    </NavLink>
                  </li>
                  <li onClick={() => setIsMenuOpen(false)}>
                    <NavLink to="/voiceAssistance" activeclassname="active">
                      <VoiceAssistance
                        color={
                          window.location.pathname === "/voiceAssistance"
                            ? "#000"
                            : "#6B6A6A"
                        }
                      />
                      <h4>{t("headerLinkThree")}</h4>
                    </NavLink>
                    {window.location.pathname === "/voiceAssistance" && (
                      <div className="navLinkWrapper"></div>
                    )}
                  </li>
                  {/* <li onClick={() => setIsMenuOpen(false)}>
                    <NavLink to="/widget" activeclassname="active">
                      <WidgetIcon
                        color={
                          window.location.pathname === "/widget"
                            ? "#000"
                            : "#6B6A6A"
                        }
                      />
                      <h4>{t("headerLinkSix")}</h4>
                    </NavLink>
                    {window.location.pathname === "/widget" && (
                      <div className="navLinkWrapper"></div>
                    )}
                  </li> */}
                  {/* <li>
                <NavLink to="/api-key" activeclassname="active">
                  <ApiKeyIcon
                    color={
                      window.location.pathname === "/api-key"
                        ? "#000"
                        : "#6B6A6A"
                    }
                  />
                  <h4>{t("headerLinkSeven")}</h4>
                </NavLink>
                {window.location.pathname === "/api-key" && (
                  <div className="navLinkWrapper"></div>
                )}
              </li> */}
                  <li onClick={() => setIsMenuOpen(false)}>
                    <NavLink to="/pricing" activeclassname="active">
                      <Pricing
                        color={
                          window.location.pathname === "/pricing"
                            ? "#000"
                            : "#6B6A6A"
                        }
                      />
                      <h4>{t("headerLinkFour")}</h4>
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="header2_mobile-section_sidebar_bottom">
                <div className="header2_mobile-section_sidebar_bottom_logout">
                  <button className="logout" onClick={handleLogout}>
                    {t("logout")}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
