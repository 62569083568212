import React, { useState, useEffect } from "react";
import axios from "axios";
import { Bars, ThreeDots } from "react-loader-spinner";
import Confirm from "../../Components/confirmModal/confirm";
import MicRecorder from "mic-recorder-to-mp3";
import "./VoiceAssistance.scss";
import { VoiceIcon, PauseIcon } from "../../Assets/icons/Icons";
import { useTranslation } from "react-i18next";
import Layout from "../../Components/Layout/Layout";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const VoiceAssistance = () => {
  const { t } = useTranslation();
  const [isRecording, setIsRecording] = useState(false);
  const [blobURL, setBlobURL] = useState("");
  const [isBlocked, setIsBlocked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showInvalidUser, setShowInvalidUser] = useState(false);
  const [messages, setMessages] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);

  const start = () => {
    if (isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true);
        })
        .catch((e) => console.error(e));
    }
  };

  const stop = async () => {
    setIsRecording(false);
    setIsLoading(true);

    try {
      const [buffer, blob] = await Mp3Recorder.stop().getMp3();
      const blobURL = URL.createObjectURL(blob);
      setBlobURL(blobURL);

      const file_upload = new File([blob], "recording.mp3", {
        type: "audio/mpeg",
      });

      const formData = new FormData();
      formData.append("file_upload", file_upload);

      const token = localStorage.getItem("token");
      let apiEndpoint = "";
      const selectedLanguage = localStorage.getItem("selectedLanguage") || "english";
      if (selectedLanguage === "english") {
        apiEndpoint = "https://ai-assistant.enovcm3c7enr4.us-east-1.cs.amazonlightsail.com/api/v1/gpt/gptPaidResponse";
      } else if (selectedLanguage === "chinese") {
        apiEndpoint = "https://ai-assistant.enovcm3c7enr4.us-east-1.cs.amazonlightsail.com/api/v1/gpt/gptChinaPaidResponse";
      }

     const response = await axios.post(
      apiEndpoint,
      formData,
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      }
    );

      if (response.data.status === 200) {
        const audioURL = response.data.data;
        playAudio(audioURL); 
      } else {
        console.error("Failed to upload audio");
        setShowInvalidUser(true);
        setMessages(response.data.message);
      }
    } catch (error) {
      console.error("Error recording audio:", error);
      setShowInvalidUser(true);
      setMessages(t("recordingError"));
    } finally {
      setIsLoading(false);
    }
  };

  const playAudio = (url) => {
    const cacheBustedURL = `${url}?_=${new Date().getTime()}`;
    const audio = new Audio(cacheBustedURL);
    audio.addEventListener("ended", handleAudioEnded);
    audio
      .play()
      .then(() => {
        setIsPlaying(true);
      })
      .catch((error) => {
        console.error("Error playing audio:", error);
        setShowInvalidUser(true);
        setMessages(t("ErrorPlaying"));
      });
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    return () => {
      const audioElement = document.getElementById("apiAudio");
      if (audioElement) {
        audioElement.removeEventListener("ended", handleAudioEnded);
      }
    };
  }, []);

  return (
    <Layout>
    <div className="va">
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <ThreeDots color="#D1D1D1" height={80} width={80} radius="9" />
          </div>
        </div>
      )}
        <div className="va_section">
          <div className="va_section_center">
            <div className="va_section_center_box">
              <div className="va_section_center_box_file">
                <div className="va_section_center_box_file_choose">
                  <button
                    className={`voice ${isRecording ? "clicked" : ""}`}
                    onClick={isRecording ? stop : start}
                    disabled={isLoading}
                  >
                    {isRecording ? <PauseIcon /> : <VoiceIcon />}
                  </button>
                  {isPlaying && (
                    <div className="loader-container2">
                      <div className="loader">
                        <Bars color="#4fa94d" height={40} width={50} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="va_section_center_box_instructions">
                <h4>{t("roleStepsTitle")}</h4>
                <ul>
                <li>{t("talkingStepOne")}</li>
                <li>{t("talkingStepTwo")}</li>
                <li>
                {t("talkingStepThree")}
                </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      {showInvalidUser && (
        <Confirm
          onCancel={() => setShowInvalidUser(false)}
          cancelButtonText="OK"
          isCancelRequired={true}
          confirmTitle={messages}
        />
      )}

      <audio id="apiAudio" controls style={{ display: "none" }} />
    </div>
    </Layout>
  );
};

export default VoiceAssistance;
