import React, { useState, useEffect } from "react";
import "./LandingHeader.scss";
import logo from "../../Assets/Images/logo.png";
import scrolledLogo from "../../Assets/Images/logo.png";
import Buttons from "../../Components/Buttons/Buttons";

const LandingHeader = ({ showButton }) => {
  const [scroll, setScroll] = useState(false);
  const [logoSrc, setLogoSrc] = useState(logo);
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 0) {
        setScroll(true);
        setLogoSrc(scrolledLogo);
      } else {
        setScroll(false);
        setLogoSrc(logo);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDropdown1 = () => {
    setShowDropdown1(!showDropdown1);
    setShowDropdown2(false);
  };

  const toggleDropdown2 = () => {
    setShowDropdown2(!showDropdown2);
    setShowDropdown1(false);
  };

  return (
    <div className="header">
      <div className={scroll ? "nav scrolled" : "nav"}>
        <div className="nav_container">
          <div className="nav_container_left">
            <img
              className="img"
              src={logoSrc}
              alt="Company Logo"
              onClick={(e) => {
                e.preventDefault();

                document
                  .querySelector("#home")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            />
          </div>
          <div className="nav_container_center">
            <ul>
              <li
                onClick={(e) => {
                  e.preventDefault();

                  document
                    .querySelector("#home")
                    .scrollIntoView({ behavior: "smooth" });
                }}
              >
                Home
              </li>

              <li
                onClick={(e) => {
                  e.preventDefault();

                  document
                    .querySelector("#about")
                    .scrollIntoView({ behavior: "smooth" });
                }}
              >
                Abouts Us
              </li>

              <li
                onClick={(e) => {
                  e.preventDefault();

                  document
                    .querySelector("#service")
                    .scrollIntoView({ behavior: "smooth" });
                }}
              >
                Services
              </li>
              <li
                onClick={(e) => {
                  e.preventDefault();

                  document
                    .querySelector("#price")
                    .scrollIntoView({ behavior: "smooth" });
                }}
              >
                Pricing
              </li>
              <li
                onClick={(e) => {
                  e.preventDefault();

                  document
                    .querySelector("#contact")
                    .scrollIntoView({ behavior: "smooth" });
                }}
              >
                Contact Us
              </li>
            </ul>
          </div>
          <div className="nav_container_right">
            <div className="nav_container_right_btn1">
              {!showButton && (
                <Buttons variant="primary" onClick={toggleDropdown1}>
                  Sign In
                </Buttons>
              )}
              {showDropdown1 && (
                <div className="dropdown1">
                  <ul>
                    <a href="/login">
                      <li>Regular User</li>
                    </a>
                    <a
                      href="https://enterprise.myaisquad.com/enterprise/user/login"
                      target="_blank"
                    >
                      <li>Enterprise User</li>
                    </a>
                    <a href="https://enterprise.myaisquad.com/" target="_blank">
                      <li>Enterprise Admin</li>
                    </a>
                  </ul>
                </div>
              )}
            </div>
            <div className="nav_container_right_btn2">
              {!showButton && (
                <Buttons variant="primary" onClick={toggleDropdown2}>
                  Sign Up
                </Buttons>
              )}
              {showDropdown2 && (
                <div className="dropdown2">
                  <ul>
                    <a href="/sign-up">
                      <li>Regular User</li>
                    </a>
                    <a
                      href="https://enterprise.myaisquad.com/enterprise-signup"
                      target="_blank"
                    >
                      <li>Enterprise Admin</li>
                    </a>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingHeader;
