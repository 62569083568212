import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Login from "../Pages/Login/Login";
import Header from "../Components/Header/Header";
import SignUp from "../Pages/SignUp/SignUp";
import Role from "../Pages/Role/Role";
import Upload from "../Pages/Upload/Upload";
import Pricing from "../Pages/Pricing/Pricing";
import { UserProvider } from "../store/UserDetailsStore";
import Dashboard from "../Pages/Dashboard/Dashboard";
import TermsAndConditionsContent from "../Pages/TermsAndConditionsContent/TermsAndConditionsContent";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import ProtectedRoute from "./ProtectedRoute";
import VoiceAssistance from "../Pages/voiceAssitance/VoiceAssistance";
import ReactRecorder from "../Pages/ReactRecorder/ReactRecorder";
import Widget from "../Pages/widget/Widget";
import WidgetLayout from "../Pages/WidgetLayout/WidgetLayout";
import ApiKey from "../Pages/ApiKey/ApiKey";
import WidgetLogin from "../Pages/WidgetLogin/WidgetLogin";
import DeleteAccount from "../Pages/DeleteAccount/DeleteAccount";
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";
import UserConfirm from "../Pages/UserConfirm/UserConfirm";
import LandingPage from "../Pages/LandingPage/LandingPage";
import PaymentSuccess from "../Pages/PaymentSucess/PaymentSucess";
import PaymentCancel from "../Pages/PaymentCancel/PaymentCancel";
import Tutor from "../Pages/Tutor/Tutor";

const RouteContent = () => {
  const { pathname } = useLocation();
  const hideHeader =
    pathname === "/" ||
    pathname === "/login" ||
    pathname === "/sign-up" ||
    pathname === "/terms-and-condition" ||
    pathname === "/privacy-policy" ||
    pathname === "/widget-layout" ||
    pathname === "/forgot-password" ||
    pathname === "/reset-password" ||
    pathname === "/confirm" ||
    pathname === "/widget-login" ||
    pathname === "/payment-success" ||
    pathname === "/payment-cancel" ||
    pathname === "/tutor";

  return (
    <>
      {!hideHeader && <Header />}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/confirm" element={<UserConfirm />} />
        <Route path="/tutor" element={<Tutor />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/role"
          element={
            <ProtectedRoute>
              <Role />
            </ProtectedRoute>
          }
        />
        <Route
          path="/upload"
          element={
            <ProtectedRoute>
              <Upload />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pricing"
          element={
            <ProtectedRoute>
              <Pricing />
            </ProtectedRoute>
          }
        />
        <Route
          path="/voiceAssistance"
          element={
            <ProtectedRoute>
              <VoiceAssistance />
            </ProtectedRoute>
          }
        />
        <Route
          path="/widget"
          element={
            <ProtectedRoute>
              <Widget />
            </ProtectedRoute>
          }
        />
        <Route
          path="/api-key"
          element={
            <ProtectedRoute>
              <ApiKey />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment-cancel"
          element={
            <ProtectedRoute>
              <PaymentCancel />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment-success"
          element={
            <ProtectedRoute>
              <PaymentSuccess />
            </ProtectedRoute>
          }
        />
        <Route
          path="/terms-and-condition"
          element={<TermsAndConditionsContent />}
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/recorder" element={<ReactRecorder />} />
        <Route path="/widget-login" element={<WidgetLogin />} />
        <Route path="/widget-layout" element={<WidgetLayout />} />
        <Route path="/delete-account" element={<DeleteAccount />} />
      </Routes>
    </>
  );
};

const RouterComponent = () => {
  return (
    <BrowserRouter>
      <UserProvider>
        <RouteContent />
      </UserProvider>
    </BrowserRouter>
  );
};

export default RouterComponent;
