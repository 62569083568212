import React, { useEffect, useState } from "react";
import "./SignUp.scss";
import Signupimg from "../../Assets/Images/signupimg.png";
import logo from "../../Assets/Images/logo.png";
import FormInputs from "../../Components/FormInputs/FormInputs";
import GoogleLogin from "../../Assets/Images/GoogleLogin.png";
import AppleLogo from "../../Assets/Images/AppleLogo.png";
import { BsEyeSlash } from "react-icons/bs";
import { BsEye } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { AppleAuth, GoogleAuth, UserSignup } from "../../utils/apiCalls";
import { ThreeDots } from "react-loader-spinner";
import Confirm from "../../Components/confirmModal/confirm";
import Google from "../../Assets/Images/GoogleSignIn.png";
import { useTranslation } from "react-i18next";
import { OAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../Config/firebaseConfig";

const initialValues = {
  fullName: "",
  email: "",
  password: "",
  agree: false,
};

const SignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [signup, setSignup] = useState(initialValues);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showInvalidUser, setShowInvalidUser] = useState(false);
  const [messages, setMessages] = useState("");
  const [appleError, setAppleError] = useState(null);

  const handlePrivacyPolicy = () => {
    navigate("/privacy-policy");
  };

  const handleTermsAndConditions = () => {
    navigate("/terms-and-condition");
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    const inputValue = type === "checkbox" ? checked : value;

    setSignup({
      ...signup,
      [name]: inputValue,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateFields = () => {
    let errObj = { ...initialValues };

    if (!signup.fullName.trim()) {
      errObj.fullName = t("errorOne");
    } else if (!/^[A-Za-z\s]*$/.test(signup.fullName)) {
      errObj.fullName = t("nameError");
    } else {
      errObj.fullName = "";
    }
    if (!signup.email) {
      errObj.email = t("errorOne");
    } else if (!/\S+@\S+\.\S+/.test(signup.email)) {
      errObj.email = t("emailError");
    } else {
      errObj.email = "";
    }
    if (!signup.password) {
      errObj.password = t("errorOne"); // Assuming t() is a function for translation
    } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(signup.password)) {
      errObj.password = t("signUpPasswordError");
    } else {
      errObj.password = "";
    }

    if (!signup.agree) {
      errObj.agree = t("checkBoxError");
    } else {
      errObj.agree = "";
    }

    setErrors((prev) => ({ ...prev, ...errObj }));
    const data = Object.values(errObj).every((x) => x === "" || x === null);
    return data;
  };

  const handleSignup = () => {
    if (validateFields()) {
      setIsLoading(true);
      const payload = {
        fullName: signup.fullName,
        email: signup.email,
        password: signup.password,
      };

      UserSignup((res) => {
        const { status, message } = res;
        if (status === 200) {
          setIsLoading(false);
          setShowInvalidUser(true);
          setMessages(message);
        } else {
          setIsLoading(false);
          setShowInvalidUser(true);
          setMessages(message);
        }
      }, payload);
    }
  };

  const handleLoginInPage = () => {
    navigate("/login");
  };

  const handleGoogleLogin = () => {
    GoogleAuth((res) => {
      const googleAuthUrl =
        "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?response_type=code&redirect_uri=https%3A%2F%2Fapi.myaisquad.com%2Fapi%2Fv1%2Fuseraccess%2Fgoogle%2Fredirect&scope=email%20profile&client_id=366492047665-geokskmct2lejck3hgfhdpoub7s8cv7a.apps.googleusercontent.com&service=lso&o2v=2&theme=glif&flowName=GeneralOAuthFlow";
      window.open(googleAuthUrl, "_self");
    });
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tokenFromUrl = searchParams.get("token");

  useEffect(() => {
    if (tokenFromUrl) {
      localStorage.setItem("token", tokenFromUrl);
      navigate("/role");
    }
  }, [tokenFromUrl]);

  const handleAppleLogin = async () => {
    setIsLoading(false);   
    const provider = new OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const identityToken = await result?._tokenResponse?.oauthIdToken;

      const payload = {
        identityToken: identityToken,
        email: user.email,
        familyName: user.displayName,
        givenName: user.displayName,
      };

      AppleAuth((res) => {
        const { status, data, message } = res;
        if (status === 200) {
          localStorage.setItem("token", data.token);
          navigate("/role");
          setIsLoading(false);
        } else {
          setShowInvalidUser(true);
          setIsLoading(false);
          setMessages(message);
        }
      }, payload);
    } catch (error) {
      setAppleError(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("User is signed in:", user);
      } else {
        console.log("No user is signed in");
      }
    });
    return () => unsubscribe();
  }, []);


  return (
    <div className="signup">
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <ThreeDots color="#D1D1D1" height={80} width={80} radius="9" />
          </div>
        </div>
      )}
      <div className="signup_container">
        <div className="signup_section">
          <div className="signup_section_left">
            <img src={Signupimg} alt="signupimg" />
          </div>
          <div className="signup_section_right">
            <div className="signup_section_right_box">
              <div className="signup_section_right_box_header">
                <div className="signup_section_right_box_header_heading">
                  <img src={logo} alt="logo" />
                  <h3>{t("loginTitle")}</h3>
                </div>
                <div className="signup_section_right_box_header_content">
                  <p>{t("signupContent")}</p>
                </div>
              </div>
              <div className="signup_section_right_box_content">
                <div className="signup_section_right_box_content_inputs">
                  <div className="signup_section_right_box_content_inputs_name">
                    <FormInputs
                      title={t("fullNameTitle")}
                      type={"text"}
                      name="fullName"
                      value={signup.fullName}
                      onChange={handleChange}
                      icon={false}
                      placeholder={t("namePlaceholder")}
                    />
                    <div>
                      {errors.fullName && (
                        <span className="error">{errors.fullName}</span>
                      )}
                    </div>
                  </div>
                  <div className="signup_section_right_box_content_inputs_email">
                    <FormInputs
                      title={t("emailTitle")}
                      type={"email"}
                      name="email"
                      value={signup.email}
                      onChange={handleChange}
                      icon={false}
                      placeholder={t("emailPlaceholde")}
                    />
                    <div>
                      {errors.email && (
                        <span className="error">{errors.email}</span>
                      )}
                    </div>
                  </div>
                  <div className="signup_section_right_box_content_inputs_password">
                    <FormInputs
                      title={t("passwordTitle")}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={signup.password}
                      onChange={handleChange}
                      icon={false}
                      placeholder={t("passwordPlaceholde")}
                    />
                    <BsEyeSlash
                      style={{ display: showPassword ? "none" : "block" }}
                      className="eyeslashicon"
                      onClick={togglePasswordVisibility}
                    />
                    <BsEye
                      style={{ display: showPassword ? "block" : "none" }}
                      className="eyeicon"
                      onClick={togglePasswordVisibility}
                    />
                    <div>
                      {errors.password && (
                        <span className="error">{errors.password}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="signup_section_right_box_content_checkbox">
                  <input
                    type="checkbox"
                    name="agree"
                    checked={signup.agree}
                    onChange={handleChange}
                  />{" "}
                  <p>
                    {t("checkBoxContent")}{" "}
                    <span onClick={handleTermsAndConditions}>
                      {t("termsAndConditions")}
                    </span>{" "}
                    {t("and")}{" "}
                    <span onClick={handlePrivacyPolicy}>
                      {t("privacyPolicy")}
                    </span>
                  </p>
                </div>
                <div>
                  {errors.agree && (
                    <span className="error">{errors.agree}</span>
                  )}
                </div>

                <div className="signup_section_right_box_content_button">
                  <button onClick={handleSignup}>{t("buttonSignup")}</button>
                </div>
                <div className="signup_section_right_box_content_border">
                  <div className="line"></div>
                  <h3>{t("or")}</h3>
                  <div className="line"></div>
                </div>
                {/* <div
                  className="signup_section_right_box_content_appstore"
                  onClick={handleGoogleLogin}
                >
                  <div className="signup_section_right_box_content_appstore_google">
                    <img src={Google} alt="google" />
                  </div>
                  <div className="signup_section_right_box_content_appstore_text">
                    <h3>{t("googleButton")}</h3>
                  </div>
                 
                </div> */}
                <div className="signup_section_right_box_content_appstore">
                   <div className="signup_section_right_box_content_appstore_google" onClick={handleGoogleLogin}>
                    <img src={GoogleLogin} alt="Googlesignup" />
                  </div>
                  <div className="signup_section_right_box_content_appstore_apple" onClick={handleAppleLogin}>
                    <img src={AppleLogo} alt="apple" />
                  </div>
                </div>
                <div className="signup_section_right_box_content_bottom">
                  <p>
                    {t("signupToLogin")}{" "}
                    <span onClick={handleLoginInPage}>{t("signIn")}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showInvalidUser && (
        <Confirm
          onCancel={() => setShowInvalidUser(false)}
          // onConfirm={() => {
          //   setShowInvalidUser(false);
          //   navigate("/sign-up");
          // }}
          cancelButtonText="OK"
          isCancelRequired={true}
          // buttonText={"Sign Up"}
          confirmTitle={messages}
        />
      )}
    </div>
  );
};

export default SignUp;
