import React from "react";
import "./Widget.scss";
import Layout from "../../Components/Layout/Layout";
import Chatbot from "../../Components/Chatbot/Chatbot";

const Widget = () => {
  return (
    <Layout>
      <div className="chatbot1">
        <div className="chatbot1_main">
          <div className="chatbot1_main_left">
            <div className="chatbot1_main_left_content">
              <h3>Instructions:</h3>
              <p>1. Download the script file.</p>
              <p>
                2. Copy the code and paste it into your index.html file in your
                project so you will be able to use the widget modal on all
                pages.
              </p>
              <p>
                3. You need to sign in using your MyAISquad account details to
                log into the widget.
              </p>
              <p>
                4. If you want to use it on only a particular page, copy and
                paste the script into your project pages.
              </p>
              <p>
                5. For the demo widget, click on the MyAISquad logo at the
                bottom right corner to use the demo widget.
              </p>
            </div>
          </div>
          <div className="chatbot1_main_right"></div>
        </div>
      </div>
      <Chatbot />
    </Layout>
  );
};

export default Widget;
