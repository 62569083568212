import React, { useState } from "react";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import "./PaymentCancel.scss";

const PaymentCancel = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState("");

  const handleSubscriptionPage = () => {
    navigate("/pricing");
  };

  return (
    <div className="pc">
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <Oval
              color="#86d3ff"
              height={50}
              width={50}
              radius="7"
              secondaryColor="#86d3ff"
            />
          </div>
        </div>
      )}

      {!isLoading && (
        <div className="pc_container">
          <div className="pc_container_modal">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 130.2 130.2"
            >
              <circle
                class="path circle"
                fill="none"
                stroke="#D06079"
                stroke-width="6"
                stroke-miterlimit="10"
                cx="65.1"
                cy="65.1"
                r="62.1"
              />
              <line
                class="path line"
                fill="none"
                stroke="#D06079"
                stroke-width="6"
                stroke-linecap="round"
                stroke-miterlimit="10"
                x1="34.4"
                y1="37.9"
                x2="95.8"
                y2="92.3"
              />
              <line
                class="path line"
                fill="none"
                stroke="#D06079"
                stroke-width="6"
                stroke-linecap="round"
                stroke-miterlimit="10"
                x1="95.8"
                y1="38"
                x2="34.4"
                y2="92.2"
              />
            </svg>
            <div className="pc_container_text">
              {/* <p className="error">{messages}</p> */}
              <p className="error">Payment Canceled!</p>
              <p>Your payment has been Canceled please try again...!!! </p>
            </div>
            <div className="pc_container_button">
              <button onClick={handleSubscriptionPage}>Go Back</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentCancel;
