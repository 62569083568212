import React, { useEffect, useRef, useState } from "react";
import "./WidgetLayout.scss";
import chatbotmsg from "../../Assets/Images/chatbotmsg.png";
import chatbotsend from "../../Assets/Images/chatbotsend.png";
// import { claudeTextChatResponse } from "../../utils/apiCalls";
import loader from "../../Assets/Images/loading.png";
import logo from "../../Assets/Images/logo.png";
import axios from "axios";

const WidgetLayout = () => {
  const [isMessage, setIsMessage] = useState("");
  const [isResponse, setIsResponse] = useState("");
  const [chatData, setChatData] = useState([]);
  const chatContainerRef = useRef(null);
  const textareaRef = useRef(null);

  useEffect(() => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [chatData]);

  useEffect(() => {
    if (!isResponse) {
      textareaRef.current.focus();
    }
  }, [isResponse]);

  // const handleChatResponse = async () => {
  //   setIsResponse(true);
  //   try {
  //     const res = await claudeTextChatResponse(processResponse, {
  //       question: isMessage,
  //       language: "english",
  //     });
  //   } catch (error) {
  //     setIsResponse(false);
  //     console.error("Error:", error);
  //   }
  // };

  const handleChatResponse = () => {
    setIsResponse(true);

    const payload = {
      question: isMessage,
      language: "english",
    };

    const token = localStorage.getItem("myaisquadtoken");

    axios
      .post("https://ai-assistant.enovcm3c7enr4.us-east-1.cs.amazonlightsail.com/api/v1/chat/claudeTextChatReponse", payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        setIsResponse(false);
        setChatData([
          ...chatData,
          { question: isMessage, result: response?.data?.data?.result },
        ]);
        setIsMessage("");
      })
      .catch((error) => {
        setIsResponse(false);
        console.error("Error:", error);
      });
  };

  // const processResponse = (response) => {
  //   setChatData([
  //     ...chatData,
  //     { question: isMessage, result: response.data.result },
  //   ]);
  //   setIsMessage("");
  //   setIsResponse(false);
  // };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleChatResponse();
    } else if (e.key === "Enter" && e.shiftKey) {
      setIsMessage(isMessage + "\n");
    }
  };

  return (
    <div className="chatbot_main">
      <div className="chatbot_main_container">
        <div className="chatbot_main_suggestions" ref={chatContainerRef}>
          {chatData?.map((res, index) => (
            <div key={index} className="tempdata">
              <div className="chatbot_main_suggestions_box">
                <h5>{res.question}</h5>
                <p>{res.result}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="chatbot_main_message">
          <div className="chatbotmsgimg">
            <img src={chatbotmsg} alt="chatbotmsg" />
          </div>
          <textarea
            ref={textareaRef}
            type="text"
            placeholder="Send a message"
            value={isMessage}
            disabled={isResponse === true}
            onChange={(e) => setIsMessage(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <div className="chatbotsendimg">
            {isResponse === true ? (
              <img className="loader" src={loader} alt="Loading" />
            ) : (
              <img
                src={chatbotsend}
                alt="chatbotsend"
                onClick={handleChatResponse}
              />
            )}
          </div>
        </div>
      </div>
      <div className="chatbot_poweredby">
        <img src={logo} alt="logo" />
        <p>
          Powered by{" "}
          <a href="https://myaisquad.com/" target="_blank">
            myaisquad.com
          </a>{" "}
        </p>
      </div>
    </div>
  );
};

export default WidgetLayout;
