import React from "react";
import "./FormInputs.scss";
import { FaIcons } from "react-icons/fa6";


const FormInputs = ({ title, type, value, onChange, name, placeholder, icon,onKeyDown }) => {
  return (
    <div className="forminput">
        <span>{title}</span>
      <div className="input-container">
          <input className={`${icon ? 'input-icons' : 'input'}`} onKeyDown={onKeyDown} type={type} name={name} value={value} onChange={onChange} placeholder={placeholder} maxLength={30}/>
      </div>
    </div>
  );
};

export default FormInputs;
