import React, { useEffect, useState } from "react";
import "./Pricing.scss";
import { useTranslation } from "react-i18next";
import Layout from "../../Components/Layout/Layout";
import { LightBlueArrow } from "../../Assets/icons/Icons";
import Buttons from "../../Components/Buttons/Buttons";
import {
  AddOnPlan,
  GetSubscriptionDetails,
  SubscriptionPlan,
} from "../../utils/apiCalls";
import { ThreeDots } from "react-loader-spinner";
import Confirm from "../../Components/confirmModal/confirm";

const Pricing = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [statusData, setStatusData] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showInvalidUser, setShowInvalidUser] = useState(false);
  const [messages, setMessages] = useState("");

  const subscriptionDetails = () => {
    setIsLoading(true);
    GetSubscriptionDetails((res) => {
      const { status, data } = res;
      if (status === 200) {
        setStatusData(data);
      } else {
        setStatusData(null);
      }
      setIsLoading(false);
      setHasFetched(true);
    });
  };

  useEffect(() => {
    subscriptionDetails();
  }, []);

  const handlePayment = (planType) => {
    if (statusData) {
      setShowPopup(true);
      return;
    }
    setIsLoading(true);
    const payload = {
      subscriptionType: planType,
    };
    SubscriptionPlan((res) => {
      const { status, data, message } = res;
      if (status === 200) {
        setIsLoading(false);
        window.open(data.url, "_blank");
      } else {
        setIsLoading(false);
        setShowInvalidUser(true);
        setMessages(message);
      }
    }, payload);
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleAddOnPlan = (addOnType) => {
    setIsLoading(true);
    const payload = {
      addOnType: addOnType,
    };
    AddOnPlan((res) => {
      const { status, data, message } = res;
      if (status === 200) {
        setIsLoading(false);
        window.open(data.url, "_blank");
      } else {
        setIsLoading(false);
        setShowInvalidUser(true);
        setMessages(message);
      }
    }, payload);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().padStart(4, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${day}/${month}/${year}`;
  };

  return (
    <Layout>
      <div className="mainp">
        {isLoading && (
          <div className="loader-container">
            <div className="loader">
              <ThreeDots color="#D1D1D1" height={80} width={80} radius="9" />
            </div>
          </div>
        )}
        <div className="mainp_section">
          <div className="mainp_section_center">
            <div className="mainp_section_center_header">
              <h2>{t("priceHeader")}</h2>
            </div>
            <div className="mainp_section_center_price">
              <div className="mainp_section_center_price_cont">
                <div className="mainp_section_center_price_cont_sec">
                  {hasFetched && !isLoading && (
                    <>
                      {statusData === null ? (
                        <>
                          <div className="mainp_section_center_price_cont_sec_plan">
                            <h3>{t("pricePlanA")}</h3>
                            <p className="text">
                              <strong>{t("pricePlanACostStrong")}</strong>
                              <small>{t("pricePlanACostsmall")}</small>
                            </p>
                            <h5>{t("priceBilled")}</h5>
                            <p>{t("pricePalnSubHead")}</p>
                            <Buttons
                              onClick={() => handlePayment("standard")}
                              variant="primary"
                            >
                              {t("priceButtonBuy")}
                            </Buttons>
                            <ul>
                              <li>
                                <span className="icon">
                                  <LightBlueArrow />
                                </span>
                                <span>{t("pricePlanAPoint1")}</span>
                              </li>
                              <li>
                                <span className="icon">
                                  <LightBlueArrow />
                                </span>
                                <span>{t("pricePlanAPoint4")}</span>
                              </li>
                              <li>
                                <span className="icon">
                                  <LightBlueArrow />
                                </span>
                                <span>{t("pricePlanAPoint5")}</span>
                              </li>
                              <li>
                                <span className="icon">
                                  <LightBlueArrow />
                                </span>
                                <span>{t("pricePlanAPoint6")}</span>
                              </li>
                              <li>
                                <span className="icon">
                                  <LightBlueArrow />
                                </span>
                                <span>{t("pricePlanAPoint10")}</span>
                              </li>
                            </ul>
                          </div>
                          <div className="mainp_section_center_price_cont_sec_plan">
                            <h3>{t("pricePlanB")}</h3>
                            <p className="text">
                              <strong>{t("pricePlanBCostStrong")}</strong>
                              <small>{t("pricePlanACostsmall")}</small>
                            </p>
                            <h5>{t("priceBilled")}</h5>
                            <p>{t("pricePalnSubHead")}</p>
                            <Buttons
                              onClick={() => handlePayment("plus")}
                              variant="primary"
                            >
                              {t("priceButtonBuy")}
                            </Buttons>
                            <ul>
                              <li>
                                <span className="icon">
                                  <LightBlueArrow />
                                </span>
                                <span>{t("pricePlanAPoint8")}</span>
                              </li>
                              <li>
                                <span className="icon">
                                  <LightBlueArrow />
                                </span>
                                <span>{t("pricePlanAPoint4")}</span>
                              </li>
                              <li>
                                <span className="icon">
                                  <LightBlueArrow />
                                </span>
                                <span>{t("pricePlanAPoint5")}</span>
                              </li>
                              <li>
                                <span className="icon">
                                  <LightBlueArrow />
                                </span>
                                <span>{t("pricePlanAPoint6")}</span>
                              </li>
                              <li>
                                <span className="icon">
                                  <LightBlueArrow />
                                </span>
                                <span>{t("pricePlanAPoint10")}</span>
                              </li>
                            </ul>
                          </div>
                        </>
                      ) : (
                        <>
                          {statusData.subscriptionType === "standard" && (
                            <div className="mainp_section_center_price_cont_sec_plan">
                              <h3>{t("pricePlanA")}</h3>
                              <p className="text">
                                <strong>{t("pricePlanACostStrong")}</strong>
                                <small>{t("pricePlanACostsmall")}</small>
                              </p>
                              <h5>{t("priceBilled")}</h5>
                              <p>{t("pricePalnSubHead")}</p>
                              <Buttons
                                onClick={() => setShowPopup(true)}
                                variant="primary"
                              >
                                {statusData
                                  ? t("buttonViewDetails")
                                  : t("priceButtonBuy")}
                              </Buttons>
                              <ul>
                                <li>
                                  <span className="icon">
                                    <LightBlueArrow />
                                  </span>
                                  <span>{t("pricePlanAPoint1")}</span>
                                </li>
                                <li>
                                  <span className="icon">
                                    <LightBlueArrow />
                                  </span>
                                  <span>{t("pricePlanAPoint4")}</span>
                                </li>
                                <li>
                                  <span className="icon">
                                    <LightBlueArrow />
                                  </span>
                                  <span>{t("pricePlanAPoint5")}</span>
                                </li>
                                <li>
                                  <span className="icon">
                                    <LightBlueArrow />
                                  </span>
                                  <span>{t("pricePlanAPoint6")}</span>
                                </li>
                                <li>
                                  <span className="icon">
                                    <LightBlueArrow />
                                  </span>
                                  <span>{t("pricePlanAPoint10")}</span>
                                </li>
                              </ul>
                            </div>
                          )}
                          {statusData.subscriptionType === "plus" && (
                            <div className="mainp_section_center_price_cont_sec_plan">
                              <h3>{t("pricePlanB")}</h3>
                              <p className="text">
                                <strong>{t("pricePlanBCostStrong")}</strong>
                                <small>{t("pricePlanACostsmall")}</small>
                              </p>
                              <h5>{t("priceBilled")}</h5>
                              <p>{t("pricePalnSubHead")}</p>
                              <Buttons
                                onClick={() => setShowPopup(true)}
                                variant="primary"
                              >
                                {statusData
                                  ? t("buttonViewDetails")
                                  : t("priceButtonBuy")}
                              </Buttons>
                              <ul>
                                <li>
                                  <span className="icon">
                                    <LightBlueArrow />
                                  </span>
                                  <span>{t("pricePlanAPoint8")}</span>
                                </li>
                                <li>
                                  <span className="icon">
                                    <LightBlueArrow />
                                  </span>
                                  <span>{t("pricePlanAPoint4")}</span>
                                </li>
                                <li>
                                  <span className="icon">
                                    <LightBlueArrow />
                                  </span>
                                  <span>{t("pricePlanAPoint5")}</span>
                                </li>
                                <li>
                                  <span className="icon">
                                    <LightBlueArrow />
                                  </span>
                                  <span>{t("pricePlanAPoint6")}</span>
                                </li>
                                <li>
                                  <span className="icon">
                                    <LightBlueArrow />
                                  </span>
                                  <span>{t("pricePlanAPoint10")}</span>
                                </li>
                              </ul>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <div className="mainp_section_center_price_cont_sec_plan">
                    <h2>{t("addOnHeading")}</h2>
                    <div className="mainp_section_center_price_cont_sec_plan_add">
                      <p className="text2">
                        <strong>{t("priceAddOnPlanA")}</strong>
                        <small>/{t("currentPlan")}</small>
                      </p>

                      <ul>
                        <li>
                          <span className="icon">
                            <LightBlueArrow />
                          </span>
                          <span>{t("pricePlanAddPoint1")}</span>
                        </li>
                        <li>
                          <span className="icon">
                            <LightBlueArrow />
                          </span>
                          <span>{t("pricePlanAddPoint2")}</span>
                        </li>
                      </ul>
                      <div className="mainp_section_center_price_cont_sec_plan_add_button">
                        <Buttons
                          onClick={() => handleAddOnPlan("AddOnPlan1")}
                          variant="primary"
                        >
                          {t("addOnButtonA")}
                        </Buttons>
                      </div>
                    </div>
                    <div className="mainp_section_center_price_cont_sec_plan_add">
                      <p className="text2">
                        <strong>{t("priceAddOnPlanB")}</strong>
                        <small>/{t("currentPlan")}</small>
                      </p>
                      <ul>
                        <li>
                          <span className="icon">
                            <LightBlueArrow />
                          </span>
                          <span>{t("pricePlanAddPoint1")}</span>
                        </li>
                        <li>
                          <span className="icon">
                            <LightBlueArrow />
                          </span>
                          <span>{t("pricePlanAddPoint2")}</span>
                        </li>
                      </ul>
                      <div className="mainp_section_center_price_cont_sec_plan_add_button">
                        <Buttons
                          onClick={() => handleAddOnPlan("AddOnPlan2")}
                          variant="primary"
                        >
                          {t("addOnButtonA")}
                        </Buttons>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="details">
          <div className="details_popup">
            <div className="details_popup_header">
              <h2>{t("planDetailsHeading")}</h2>
            </div>
            <div className="details_popup_body">
              <div className="details_popup_body_top">
                <h2>{statusData?.subscriptionType}</h2>
                <span>{t("planDetailsCurrentPlan")}</span>
              </div>
              <div className="details_popup_body_center">
                <span>
                {t("planDetailsInputTokens")}: <strong>{statusData?.inputTokenCount}</strong>
                </span>
                <span>
                {t("planDetailsOutputTokens")}: <strong>{statusData?.outputTokenCount}</strong>
                </span>
                <span>
                {t("planDetailsAddInputTokens")}:{" "}
                  <strong>{statusData?.additionalInputTokenCount}</strong>
                </span>
                <span>
                {t("planDetailsAddOutputTokens")}:{" "}
                  <strong>{statusData?.additionalOutputTokenCount}</strong>
                </span>
                <span>
                {t("planDetailsStartDate")}:{" "}
                  <strong>
                    {formatDate(statusData?.subscriptionStartDate)}
                  </strong>
                </span>
                <span>
                  {t("planDetailsEndDate")}:{" "}
                  <strong>{formatDate(statusData?.subscriptionEndDate)}</strong>
                </span>
              </div>
            </div>
            <div className="details_popup_buttom">
              <button onClick={handleClose}>OK</button>
            </div>
          </div>
        </div>
      )}
      {showInvalidUser && (
        <Confirm
          onCancel={() => setShowInvalidUser(false)}
          cancelButtonText="OK"
          isCancelRequired={true}
          confirmTitle={messages}
        />
      )}
    </Layout>
  );
};

export default Pricing;
