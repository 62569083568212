import React, { useState } from "react";
import "./LandingFooter.scss";
import logo from "../../Assets/Images/logo.png";
import { FaFacebookF } from "react-icons/fa";
import { IoLogoInstagram, IoLogoYoutube } from "react-icons/io";
import { FaLinkedinIn } from "react-icons/fa6";
import Buttons from "../../Components/Buttons/Buttons";
import axios from "axios";
import { CgCloseO } from "react-icons/cg";

const LandingFooter = () => {
  const [email, setEmail] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const handleSubscribe = async () => {
    if (email.trim() === "") {
      alert("Please enter a valid email.");
      return;
    }

    try {
      const response = await axios.post(
        "https://api.myaisquad.com/api/v1/useraccess/newsletter",
        { email }
      );
      if (response.status === 200) {
        setShowPopup(true);
        setEmail("");
      } else {
        setEmail("");
      }
    } catch (error) {
      console.error("There was an error subscribing:", error);
      alert("Failed to subscribe. Please try again later.");
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="main">
      <div className="footer">
        <div className="footer_cont">
          <div className="footer_cont_sec">
            <div className="footer_cont_sec_left">
              <img
                src={logo}
                alt="logo"
                onClick={(e) => {
                  e.preventDefault();

                  document
                    .querySelector("#home")
                    .scrollIntoView({ behavior: "smooth" });
                }}
              />
              <p>
                "Empowering your business with cutting-edge AI solutions,
                driving innovation, and enhancing customer experiences in the
                digital age!"
              </p>
              <div className="footer_cont_sec_left_links">
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF className="link_zoom" />
                </a>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IoLogoInstagram className="link_zoom" />
                </a>
                <a
                  href="https://www.linkedin.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn className="link_zoom" />
                </a>
                <a
                  href="https://www.youtube.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IoLogoYoutube className="link_zoom" />
                </a>
              </div>
            </div>
            <div className="footer_cont_sec_right">
              <div className="footer_cont_sec_right_links">
                <div className="footer_cont_sec_right_links_header">
                  <h2>Useful Links</h2>
                </div>
                <div className="footer_cont_sec_right_links_content">
                  <ul>
                    <li
                      onClick={(e) => {
                        e.preventDefault();

                        document
                          .querySelector("#home")
                          .scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      Home
                    </li>

                    <li
                      onClick={(e) => {
                        e.preventDefault();

                        document
                          .querySelector("#about")
                          .scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      Abouts Us
                    </li>

                    <li
                      onClick={(e) => {
                        e.preventDefault();

                        document
                          .querySelector("#service")
                          .scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      Services
                    </li>

                    <li
                      onClick={(e) => {
                        e.preventDefault();

                        document
                          .querySelector("#key")
                          .scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      Key Features
                    </li>

                    <li
                      onClick={(e) => {
                        e.preventDefault();

                        document
                          .querySelector("#price")
                          .scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      Pricing
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer_cont_sec_right_services">
                <div className="footer_cont_sec_right_services_header">
                  <h2>Useful Information's</h2>
                </div>
                <div className="footer_cont_sec_right_services_content">
                  <ul>
                    <li
                      onClick={(e) => {
                        e.preventDefault();

                        document
                          .querySelector("#blog")
                          .scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      Blogs
                    </li>

                    <li
                      onClick={(e) => {
                        e.preventDefault();

                        document
                          .querySelector("#contact")
                          .scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      Contact Us
                    </li>
                    <a
                      href="https://myaisquad.com/privacy-policy"
                      target="_blank"
                    >
                      <li>Privacy Policy</li>
                    </a>
                    <a
                      href="https://myaisquad.com/terms-and-condition"
                      target="_blank"
                    >
                      <li>Terms & Conditions</li>
                    </a>
                  </ul>
                </div>
              </div>
              <div className="footer_cont_sec_right_contact">
                <div className="footer_cont_sec_right_contact_header">
                  <h2>News & Update</h2>
                </div>
                <div className="footer_cont_sec_right_contact_content">
                  <input
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Buttons variant="secondary" onClick={handleSubscribe}>
                    Subscribe
                  </Buttons>
                  <p>Subscribe our newsletter for future updates</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_bottom">
          <p>© 2024. Shameo AI. All Rights Reserved</p>
        </div>
      </div>
      {showPopup && (
        <div className="popup" onClick={handleClosePopup}>
          <div className="popup_container" onClick={(e) => e.stopPropagation()}>
            <div className="popup_header">
              <h2>Thank You...</h2>
              <div className="close" onClick={handleClosePopup}>
                <CgCloseO />
              </div>
            </div>
            <p>Successfully subscribed to newsletter</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingFooter;
