import React, { useEffect, useRef, useState } from "react";
import "./Tutor.scss";
import chatbotmsg from "../../Assets/Images/chatbotmsg.png";
import chatbotsend from "../../Assets/Images/chatbotsend.png";
import loader from "../../Assets/Images/loading.png";
import axios from "axios";
import LandingHeader from "../../Components/LandingHeader/LandingHeader";

const Tutor = () => {
    const [isMessage, setIsMessage] = useState("");
    const [isResponse, setIsResponse] = useState("");
    const [chatData, setChatData] = useState([]);
    const chatContainerRef = useRef(null);
    const textareaRef = useRef(null);

    useEffect(() => {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }, [chatData]);

    useEffect(() => {
        if (!isResponse) {
            textareaRef.current.focus();
        }
    }, [isResponse]);

    const handleChatResponse = () => {
        setIsResponse(true);

        const payload = {
            question: isMessage,
        };

        axios
            .post("https://api.myaisquad.com/api/v1/profile/tutor/math", payload,
            )
            .then((response) => {
                setIsResponse(false);
                setChatData([
                    ...chatData,
                    { question: isMessage, result: response?.data?.data?.explanation },
                ]);
                setIsMessage("");
            })
            .catch((error) => {
                setIsResponse(false);
                console.error("Error:", error);
            });
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            handleChatResponse();
        } else if (e.key === "Enter" && e.shiftKey) {
            setIsMessage(isMessage + "\n");
        }
    };

    return (
        <>
            {/* <LandingHeader /> */}
            <div className="tutor_main">
                <h2>Math Tutor</h2>
                <div className="tutor_main_container">
                    <div className="tutor_main_suggestions" ref={chatContainerRef}>
                        {chatData?.map((res, index) => (
                            <div key={index} className="tempdata">
                                <div className="tutor_main_suggestions_box">
                                    <h5>{res.question}</h5>
                                    <p>{res.result}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="tutor_main_message">
                        <div className="tutormsgimg">
                            <img src={chatbotmsg} alt="tutormsg" />
                        </div>
                        <textarea
                            ref={textareaRef}
                            type="text"
                            placeholder="Send a message"
                            value={isMessage}
                            disabled={isResponse === true}
                            onChange={(e) => setIsMessage(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                        <div className="tutorsendimg">
                            {isResponse === true ? (
                                <img className="loader" src={loader} alt="Loading" />
                            ) : (
                                <img
                                    src={chatbotsend}
                                    alt="tutorsend"
                                    onClick={handleChatResponse}
                                />
                            )}
                        </div>
                    </div>
                </div>

            </div>
        </>

    );
};

export default Tutor;
