import React, { useEffect, useState } from "react";
import "../Login/Login.scss";
import loginimg from "../../Assets/Images/loginimg.png";
import logo from "../../Assets/Images/logo.png";
import FormInputs from "../../Components/FormInputs/FormInputs";
import { BsEyeSlash } from "react-icons/bs";
import { BsEye } from "react-icons/bs";
import { ResetPasswordApi } from "../../utils/apiCalls";
import { useLocation, useNavigate } from "react-router-dom";
import Confirm from "../../Components/confirmModal/confirm";
import { ThreeDots } from "react-loader-spinner";
import { EmailIcon, PasswordLock } from "../../Assets/icons/Icons";
import { useTranslation } from "react-i18next";

const initialValues = {
  email: "",
  password: "",
  confirmPassword: "",
};

const ResetPassword = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [login, setLogin] = useState(initialValues);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState(initialValues);
  const [showInvalidUser, setShowInvalidUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    setLogin({
      ...login,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateFields = () => {
    let errObj = { ...initialValues };

    if (!login.email) {
      errObj.email = t("errorOne");
    } else if (!/\S+@\S+\.\S+/.test(login.email)) {
      errObj.email = t("emailError");
    } else {
      errObj.email = "";
    }

    if (!login.password) {
      errObj.password = t("errorOne");
    } else if (login.password.length < 8) {
      errObj.password = t("passwordError");
    } else {
      errObj.password = "";
    }

    if (!login.confirmPassword) {
      errObj.confirmPassword = t("errorOne");
    } else if (login.password !== login.confirmPassword) {
      errObj.confirmPassword = "Passwords do not match";
    } else {
      errObj.confirmPassword = "";
    }

    setErrors((prev) => ({ ...prev, ...errObj }));
    const data = Object.values(errObj).every((x) => x === "" || x === null);
    return data;
  };


  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tokenFromUrl = searchParams.get("token");

  useEffect(() => {
    if (tokenFromUrl) {
      localStorage.setItem("token", tokenFromUrl);
    }
  }, [tokenFromUrl]);

  const handleResetPassword = () => {
    setIsLoading(true);
    if (validateFields()) {
      const payload = {
        email: login.email,
        password: login.password,
        token: tokenFromUrl,
      };
      ResetPasswordApi((res) => {
        const { status, data, message } = res;
        if (status === 200) {
          setIsLoading(false);
          setShowInvalidUser(true);
          setMessages(message);
        } else {
          setShowInvalidUser(true);
          setIsLoading(false);
          setMessages(message);
        }
      }, payload);
    } else {
      setIsLoading(false);
    }
  };

  const handleSignUpPage = () => {
    navigate("/sign-up");
  };


  return (
    <div className="login">
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <ThreeDots color="#D1D1D1" height={80} width={80} radius="9" />
          </div>
        </div>
      )}
      <div className="login_container">
        <div className="login_section">
          <div className="login_section_left">
            <div className="login_section_left_box">
              <div className="login_section_left_box_header">
                <div className="login_section_left_box_header_heading">
                  {/* <img src={logo} alt="logo" /> */}
                  <h3>Reset Password</h3>
                </div>
                <div className="login_section_left_box_header_content">
                  <p>Create new Password</p>
                </div>
              </div>
              <div className="login_section_left_box_content">
                <div className="login_section_left_box_content_inputs">
                  <div className="login_section_left_box_content_inputs_email">
                    <FormInputs
                      title={t("emailTitle")}
                      type={"email"}
                      placeholder={t("email")}
                      name="email"
                      value={login.email}
                      onChange={handleChange}
                      icon={true}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleResetPassword();
                        }
                      }}
                    />
                    <EmailIcon className="emailicon" />
                    <div>
                      {errors.email && (
                        <span className="error">{errors.email}</span>
                      )}
                    </div>
                  </div>
                  <div className="login_section_left_box_content_inputs_password">
                    <FormInputs
                      title={t("passwordTitle")}
                      type={showPassword ? "text" : "password"}
                      placeholder={t("password")}
                      name="password"
                      value={login.password}
                      onChange={handleChange}
                      icon={true}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleResetPassword();
                        }
                      }}
                    />
                    <PasswordLock className="passwordicon" />
                    <BsEyeSlash
                      style={{ display: showPassword ? "none" : "block" }}
                      className="eyeslashicon"
                      onClick={togglePasswordVisibility}
                    />
                    <BsEye
                      style={{ display: showPassword ? "block" : "none" }}
                      className="eyeicon"
                      onClick={togglePasswordVisibility}
                    />
                    <div>
                      {errors.password && (
                        <span className="error">{errors.password}</span>
                      )}
                    </div>
                  </div>
                  <div className="login_section_left_box_content_inputs_password">
                    <FormInputs
                      // title={t("passwordTitle")}
                      title={"Confirm Password"}
                      type={showPassword ? "text" : "password"}
                      placeholder={"Confirm Password"}
                      name="confirmPassword"
                      value={login.confirmPassword}
                      onChange={handleChange}
                      icon={true}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleResetPassword();
                        }
                      }}
                    />
                    <PasswordLock className="passwordicon" />
                    <BsEyeSlash
                      style={{ display: showPassword ? "none" : "block" }}
                      className="eyeslashicon"
                      onClick={togglePasswordVisibility}
                    />
                    <BsEye
                      style={{ display: showPassword ? "block" : "none" }}
                      className="eyeicon"
                      onClick={togglePasswordVisibility}
                    />
                    <div>
                      {errors.confirmPassword && (
                        <span className="error">{errors.confirmPassword}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="login_section_left_box_content_button">
                  {/* <button onClick={handleResetPassword}>{t("buttonSignIn")}</button> */}
                  <button onClick={handleResetPassword}>Reset Password</button>

                </div>
                <div className="login_section_left_box_content_bottom">
                  <p>
                    {t("loginToSignup")}{" "}
                    <span onClick={handleSignUpPage}>{t("signUp")}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="login_section_right">
            <img src={loginimg} alt="loginimg" />
          </div>
        </div>
      </div>
      {showInvalidUser && (
        <Confirm
          onCancel={() => {
            setShowInvalidUser(false);
            navigate("/login");}}
          // onConfirm={() => {
          //   setShowInvalidUser(false);
          //   navigate("/login");
          // }}
          cancelButtonText="OK"
          isCancelRequired={true}
          confirmTitle={messages}
        />
      )}
    </div>
  );
};

export default ResetPassword;
