/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */ import axios from "axios";

export function fetchCall(callback, url, method, payload) {
  return new Promise((resolve, reject) => {
    const options = {
      method: method,
      url: url,
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(options)
      .then((response) => {
        const res = response.data;
        if (res.error?.statusCode === 401 || res.error?.status === 401) {
          console.log(res.error);
        } else {
          callback(res);
          resolve(res);
        }
      })
      .catch((error) => {
        const err = error.response ? error.response.data : error;
        callback(err);
        reject(err);
      });
  });
}

export function fetchNoCall(callback, url, method, payload) {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        const res = response.data;
        if (res.error?.statusCode === 401 || res.error?.status === 401) {
          console.log(res.error);
        } else {
          callback(res);
          resolve(res);
        }
      })
      .catch((error) => {
        const err = error.response ? error.response.data : error;
        callback(err);
        reject(err);
      });
  });
}

export function fetchLoginCall(callback, url, method, payload) {
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    const options = {
      method: method,
      url: url,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        accept: "text/plain",
        authorization: `Bearer ${token}`,
      },
    };
    axios(options)
      .then((response) => {
        const res = response.data;
        if (res.error?.statusCode === 401 || res.error?.status === 401) {
          if (res.error) {
            localStorage.setItem("url", url);
            localStorage.setItem("method", method);
            localStorage.setItem("payload", JSON.stringify(payload));
          }
          const tokenPayload = {
            refreshToken: localStorage.getItem("refreshToken"),
          };
          // getRefreshToken((response) => {
          //   const { data } = response;
          //   localStorage.setItem("token", data.accessToken);
          //   localStorage.setItem("refreshToken", data.refreshToken);
          //   const url = localStorage.getItem("url");
          //   const method = localStorage.getItem("method");
          //   let payload = localStorage.getItem("payload");
          //   if (payload !== "undefined") {
          //     payload = JSON.parse(payload);
          //     fetchCall(callback, url, method, payload);
          //   } else {
          //     fetchCall(callback, url, method);
          //   }
          // }, tokenPayload.refreshToken);
        } else {
          callback(res);
          resolve(res);
        }
      })
      .catch((error) => {
        const err = error.response ? error.response.data : error;
        callback({ error: err.message });
        console.log(err);
        reject(err);
      });
  });
}
