import React, { useContext, useEffect, useState } from "react";
import "./Role.scss";
import { ClearRole, UpdateRole, UserRole } from "../../utils/apiCalls";
import Confirm from "../../Components/confirmModal/confirm";
import { ThreeDots } from "react-loader-spinner";
import { UserContext } from "../../store/UserDetailsStore";
import { useTranslation } from "react-i18next";
import Layout from "../../Components/Layout/Layout";

const initialvalues = {
  role: "",
  specialization: "",
};
const Role = () => {
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const { isLoginUserDetails } = useContext(UserContext);
  const [userRole, setUserRole] = useState(initialvalues);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState("");
  const [errors, setErrors] = useState({});
  const [showInvalidUser, setShowInvalidUser] = useState(false);
  const [showInputs, setShowInputs] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserRole({
      ...userRole,
      [name]: value,
    });
    setErrors({
      ...userRole,
      [name]: "",
    });
  };

  const validateFields = () => {
    let errObj = { ...initialvalues };

    if (!userRole.role) {
      errObj.role = t("errorOne");
    } else {
      errObj.role = "";
    }
    if (!userRole.specialization) {
      errObj.specialization = t("errorOne");
    } else {
      errObj.specialization = "";
    }

    setErrors((prev) => ({ ...prev, ...errObj }));
    const data = Object.values(errObj).every((x) => x === "" || x === null);
    return data;
  };

  const handleCancle = () => {
    setShowInputs(false);
    fetchGetUser();
    setUserRole({
      ...initialvalues,
    });
    setErrors({
      ...initialvalues,
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    if (validateFields()) {
      const payload = {
        role: userRole.role,
        specialization: userRole.specialization,
        language: localStorage.getItem("selectedLanguage") || "english",
      };
      UpdateRole((res) => {
        const { status, data, message } = res;
        if (status === 200) {
          setIsLoading(false);
          setShowInvalidUser(true);
          setMessages(t("roleErrorTwo"));
          fetchGetUser();
          setShowInputs(false);
          setIsNewUser(false);
        } else {
          setIsLoading(false);
          setShowInvalidUser(true);
          setMessages(message);
        }
      }, payload);
    } else {
      setIsLoading(false);
      setMessages(t("roleErrorOne"));
      setShowInvalidUser(true);
    }
  };

  const fetchGetUser = () => {
    const payload = {
      language: localStorage.getItem("selectedLanguage") || "english",
    };
    UserRole((res) => {
      const { status, data, message } = res;
      if (status === 200) {
        setUserRole({
          role: data?.role,
          specialization: data?.specialization,
          userID: data?.userID,
        });
        setIsLoading(false);
        setIsNewUser(!data?.role && !data?.specialization);
      } else {
        setMessages(message);
        setUserRole(initialvalues);
        setIsLoading(false);
      }
    }, payload);
  };

  const handleClear = () => {
    setIsLoading(true);
    const payload = {
      language: localStorage.getItem("selectedLanguage") || "english",
    };
    ClearRole((res) => {
      const { status, message } = res;
      if (status === 200) {
        setUserRole({ ...initialvalues });
        setIsLoading(false);
        setShowInvalidUser(true);
        setMessages(message);
        setShowInputs(false);
        setIsNewUser(false);
      } else {
        setIsLoading(false);
      }
    }, payload);
  };
  
  useEffect(() => {
    if (isLoginUserDetails) {
      if (token !== null && token !== undefined) {
        fetchGetUser();
      }
    }
  }, [isLoginUserDetails, token, localStorage.getItem("selectedLanguage")]);

  return (
    <Layout>
    <div className="role">
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <ThreeDots color="#D1D1D1" height={80} width={80} radius="9" />
          </div>
        </div>
      )}
        <div className="role_section">
          <div className="role_section_top-header">
            <h2>{t("roleTitle")}</h2>
            <h3>{t("roleContent")}</h3>
          </div>
          <div className="role_section_center">
            <div className="role_section_center_left">
              <div className="role_section_center_left_inputs">
                <div className="role_section_center_left_inputs_role">
                  <label>{t("roleInputTitleOne")}</label>
                  <input
                    type="text"
                    placeholder={t("rolePlaceholderOne")}
                    onChange={handleChange}
                    name="role"
                    value={userRole.role}
                  />
                </div>
                <div className="role_section_center_left_inputs_specialization">
                  <label>{t("roleInputTitleTwo")}</label>
                  <input
                    type="text"
                    placeholder={t("rolePlaceholderTwo")}
                    onChange={handleChange}
                    name="specialization"
                    value={userRole.specialization}
                  />
                </div>
              </div>
              <div className="role_section_center_left_buttons">
                <div className="role_section_center_left_buttons_left">
                  <button className="clear" onClick={handleClear}>
                    {t("clearButton")}
                  </button>
                </div>
                {isNewUser && (
                  <div className="role_section_center_left_buttons_right">
                    <button className="submit" onClick={handleSubmit}>
                      {t("submitButton")}
                    </button>
                  </div>
                )}
                {!isNewUser && !showInputs && (
                  <div className="role_section_center_left_buttons_right">
                    <button className="cancel" onClick={handleCancle}>
                      {t("cancelButton")}
                    </button>
                    <button className="save" onClick={handleSubmit}>
                      {t("submitButton")}
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="role_section_center_line"></div> */}
            {/* <div className="role_section_center_right">
              <div className="role_section_center_right_instructions">
                <h4>{t("roleStepsTitle")}</h4>
                <ul>
                  <li>{t("roleStepOne")}</li>
                  <li>{t("roleStepTwo")}</li>
                  <li>{t("roleStepThree")}</li>
                  <li>{t("roleStepFour")}</li>
                  <li>{t("roleStepFive")}</li>
                  <li>{t("roleStepSix")}</li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      {showInvalidUser && (
        <Confirm
          onCancel={() => setShowInvalidUser(false)}
          // onConfirm={() => {
          //   setShowInvalidUser(false);
          //   navigate("/sign-up");
          // }}
          cancelButtonText="OK"
          isCancelRequired={true}
          // buttonText={"Sign Up"}
          confirmTitle={messages}
        />
      )}
    </div>
    </Layout>
  );
};

export default Role;
