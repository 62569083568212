import React, { useEffect, useState } from "react";
import "./Chatbot.scss";
import logo from "../../Assets/Images/logo.png";

function Chatbot() {
  // Toggle chatbot modal visibility
  const [showChatbot, setShowChatbot] = useState(false);
  const toggleChatbot = () => setShowChatbot(!showChatbot);

  // Close the modal when clicking outside of it
  const handleOutsideClick = (event) => {
    const chatbotModal = document.getElementById("chatbotModal");
    const chatbotIcon = document.getElementById("chatbotIcon");

    if (chatbotModal && chatbotIcon) {
      const isClickInsideChatbot =
        chatbotModal.contains(event.target) ||
        chatbotIcon.contains(event.target);
      if (!isClickInsideChatbot) {
        setShowChatbot(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  return (
    <>
      <div
        className="chatbot"
        id="chatbotIcon"
        onClick={toggleChatbot}
      >
        <img src={logo} alt="logo" />
      </div>
      {showChatbot && (
        <div id="chatbotModal" className="chatbot_modal">
          <iframe
            id="chatbotIframe"
            src="https://myaisquad.com/widget-login"
            style={{ width: "100%", height: "100%" }}
          ></iframe>
        </div>
      )}
    </>
  );
}

export default Chatbot;
