import "./App.scss";
import RouterComponent from "./App/RouterComponent/RouterComponent";

function App() {
  return (
    <div className="App">
      {/* <h1 className="bg-green-500 sm:bg-red-500 md:bg-blue-500 lg:bg-yellow-500 xxl:bg-cyan-500">
        Login
      </h1> */}
      <RouterComponent />
    </div>
  );
}

export default App;
