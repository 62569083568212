import React, { useEffect, useRef, useState } from "react";
import "./Upload.scss";
import { IoDocumentTextOutline } from "react-icons/io5";
import { GoTrash } from "react-icons/go";
import uploadIcon from "../../Assets/Images/uploadFile.png";
import {
  FileDelete,
  UploadLink,
  UserFiles,
  UserLinkStatus,
} from "../../utils/apiCalls";
import Confirm from "../../Components/confirmModal/confirm";
import { ThreeDots } from "react-loader-spinner";
import axios from "axios";
import { TbCode } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { LinkIcon, RecentIcon } from "../../Assets/icons/Icons";
import Layout from "../../Components/Layout/Layout";

const initialvalues = {
  link: "",
};

const Upload = () => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState("");
  const [showInvalidUser, setShowInvalidUser] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [linkCopied, setLinkCopied] = useState(false);
  const [uploadCount, setUploadCount] = useState(0);

  const handleChooseClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    // setUploadCount(uploadCount + 1);
  };

  const handleClearFile = () => {
    setFile(null);
  };

  const handleUpload = () => {
    setIsLoading(true);
    if (!file) {
      setIsLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("file_upload", file);
  
    const selectedLanguage =
      localStorage.getItem("selectedLanguage") || "english";
    const token = localStorage.getItem("token");
  
    formData.append("language", selectedLanguage);
  
    axios
      .post("https://api.myaisquad.com/api/v1/profile/uploadFiles", formData, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setIsLoading(false);
        setFile(null);
        setShowInvalidUser(true);
        setMessages(response.data.message);
        fetchUserFiles();
      })
      .catch((error) => {
        setIsLoading(false);
        setShowInvalidUser(true);
        setMessages(error.response?.data?.message || "Error uploading file.");
      });
  };  

  const fetchUserFiles = () => {
    const payload = {
      language: localStorage.getItem("selectedLanguage") || "english",
    };
    UserFiles((res) => {
      const { status, data, message } = res;
      if (status === 200 && data && data.files) {
        setFileNames(data);
      } else {
        setFileNames([]);
      }
    }, payload);
  };

  const handleDeleteFile = (file) => {
    setIsLoading(true);
    if (file) {
      const payload = {
        fileId: file?.fileId,
      };
      FileDelete((response) => {
        if (response.status === 200) {
          setShowInvalidUser(true);
          setMessages(response.message);
          fetchUserFiles();
          setIsLoading(false);
        } else {
          setShowInvalidUser(true);
          setMessages("Error deleting file");
          setIsLoading(false);
        }
      }, payload);
    }
  };

  const handleCopyLink = (fakeUrl) => {
    navigator.clipboard
      .writeText(fakeUrl)
      .then(() => {
        console.log("Link copied to clipboard");
        setLinkCopied(true);
        setTimeout(() => {
          setLinkCopied(false);
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy link: ", error);
      });
  };

  useEffect(() => {
    fetchUserFiles();
  }, [localStorage.getItem("selectedLanguage")]);

  const [uploadLink, setUploadLink] = useState(initialvalues);
  const [errors, setErrors] = useState({});
  const [linkNames, setLinkNames] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUploadLink({
      ...uploadLink,
      [name]: value,
    });
    setErrors({
      ...uploadLink,
      [name]: "",
    });
  };

  const validateFields = () => {
    let errObj = { ...initialvalues };

    if (!uploadLink.link) {
      errObj.link = t("errorOne");
    } else {
      errObj.link = "";
    }

    setErrors((prev) => ({ ...prev, ...errObj }));
    const data = Object.values(errObj).every((x) => x === "" || x === null);
    return data;
  };

  const handleCancel = () => {
    setUploadLink({
      ...initialvalues,
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    if (validateFields()) {
      const payload = {
        scrapedLink: uploadLink.link,
        language: localStorage.getItem("selectedLanguage") || "english",
      };
      UploadLink((res) => {
        const { status, message } = res;
        if (status === 200) {
          setIsLoading(false);
          setShowInvalidUser(true);
          setMessages(t("roleErrorTwo"));
        } else {
          setIsLoading(false);
          setShowInvalidUser(true);
          setMessages(message);
        }
      }, payload);
    } else {
      setIsLoading(false);
      setMessages(t("roleErrorOne"));
      setShowInvalidUser(true);
    }
  };

  const fetchUserLinkStatus = () => {
    const payload = {
      language: localStorage.getItem("selectedLanguage") || "english",
    };
    UserLinkStatus((res) => {
      const { status, data, message } = res;
      if (status === 200 && data) {
        setLinkNames(data);
      } else {
        setLinkNames([]);
        setMessages(message);
      }
    }, payload);
  };

  useEffect(() => {
    fetchUserLinkStatus();
  }, [localStorage.getItem("selectedLanguage")]);

  return (
    <Layout>
      <div className="upload">
        {isLoading && (
          <div className="loader-container">
            <div className="loader">
              <ThreeDots color="#D1D1D1" height={80} width={80} radius="9" />
            </div>
          </div>
        )}
          <div className="upload_section">
            {/* <div className="upload_section_top-header">
            <h2>Welcome to myaisquad.com!</h2>
            <h3>
            I am your AI assistant and you can talk to me about anything by using Shameo in ChatGPT on your mobile phone.
            </h3>
          </div> */}
            <div className="upload_section_center">
              <div className="upload_section_center_left">
                <div className="upload_section_center_left_text">
                  <h3>{t("uploadFileHeading")}</h3>
                </div>
                <div className="upload_section_center_left_file">
                  <div
                    className="upload_section_center_left_file_choose"
                    onClick={handleChooseClick}
                  >
                    {file ? (
                      <p>{file.name}</p>
                    ) : (
                      <>
                        <img src={uploadIcon} alt="uploadIcon" />
                        <h3>{t("uploadPDF")}</h3>
                      </>
                    )}
                  </div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    className="file-input"
                    onChange={(e) => handleFileChange(e)}
                    style={{ display: "none" }}
                  />
                </div>
                <div className="upload_section_center_left_buttons">
                  <button className="cancel" onClick={handleClearFile}>
                    {t("cancelButton")}
                  </button>
                  <button
                    className="save"
                    onClick={handleUpload}
                  >
                    {t("uploadButton")}
                  </button>
                </div>
                <div className="upload_section_center_left_recent">
                  <RecentIcon />
                  <h3>{t("recentFiles")}</h3>
                </div>
                <div className="upload_section_center_left_content">
                  {fileNames?.files?.map((file, index) => (
                    <div
                      key={index}
                      className="upload_section_center_left_content_print"
                    >
                      <div className="upload_section_center_left_content_print_left">
                        <div className="upload_section_center_left_content_print_left_icon">
                          <IoDocumentTextOutline className="document" />
                        </div>
                        <h3>{file.fileName}</h3>
                      </div>
                      <div className="upload_section_center_left_content_print_right">
                        <TbCode
                          className="share"
                          onClick={() => handleCopyLink(file.fakeUrl)}
                        />
                        <span className="tooltip">
                          {linkCopied ? "Copied!" : "Link for Shameo GPT"}
                        </span>
                        <div onClick={() => handleDeleteFile(file)}>
                        <GoTrash className="trash" />
                      </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="line"></div>
              <div className="upload_section_center_right">
                <div className="upload_section_center_right_text">
                  <h3>{t("scrapeLinkHeading")}</h3>
                </div>
                <div className="upload_section_center_right_input">
                  {/* <label>{t("uploadLinkLabel")}</label> */}
                  <input
                    type="text"
                    placeholder={t("uploadLinkPlaceholder")}
                    onChange={handleChange}
                    name="link"
                    value={uploadLink.link}
                  />
                </div>
                <div className="upload_section_center_right_buttons">
                  <button className="cancel" onClick={handleCancel}>
                    {t("cancelButton")}
                  </button>
                  <button className="save" onClick={handleSubmit}>
                    {t("uploadButton")}
                  </button>
                </div>
                <div className="upload_section_center_right_recent">
                  <RecentIcon />
                  <h3>{t("recentLinks")}</h3>
                </div>
                <div className="upload_section_center_right_content">
                  {linkNames?.map((data, index) => (
                    <div
                      key={index}
                      className="upload_section_center_right_content_print"
                    >
                      <div className="upload_section_center_right_content_print_left">
                        <div className="upload_section_center_right_content_print_left_icon">
                          <LinkIcon className="linkicon" />
                        </div>
                        <h3>{data.scrapedLink}</h3>
                      </div>
                      <div className="upload_section_center_right_content_print_right">
                        {data.status ? (
                          <h3>{t("linkStatusTrue")}</h3>
                        ) : (
                          <h3>{t("linkStatusFalse")}</h3>
                        )}
                        {/* <div onClick={() => handleDeleteFile(file)}>
                        <GoTrash className="trash" />
                      </div> */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        {showInvalidUser && (
          <Confirm
            onCancel={() => setShowInvalidUser(false)}
            // onConfirm={() => {
            //   setShowInvalidUser(false);
            //   navigate("/sign-up");
            // }}
            cancelButtonText={"OK"}
            isCancelRequired={true}
            // buttonText={"Sign Up"}
            confirmTitle={messages}
          />
        )}
      </div>
    </Layout>
  );
};

export default Upload;
