import React from "react";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

export default function BasicTooltip({ onClick, className, title }) {
  return (
    <Tooltip title={title}>
      <IconButton onClick={onClick} className={className}>
        <FileCopyIcon />
      </IconButton>
    </Tooltip>
  );
}