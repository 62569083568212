import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import englishTranslation from './locales/english.json';
import chineseTranslation from './locales/chinese.json';

const defaultLanguage = 'english';

const savedLanguage = localStorage.getItem('selectedLanguage');
const selectedLanguage = savedLanguage || defaultLanguage;

const resources = {
  english: { translation: englishTranslation },
  chinese: { translation: chineseTranslation }
};

i18n.use(initReactI18next).init({
  resources,
  lng: selectedLanguage,
  fallbackLng: defaultLanguage,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
