import React from "react";
import "./DeleteAccount.scss"
import Layout from "../../Components/Layout/Layout";

const DeleteAccount = () => {
  return (
    <Layout>
      <div className="acc">
        <div className="acc_sec">
          <div className="acc_sec_header">
            <h3>DELETE ACCOUNT</h3>
          </div>
          <div className="acc_sec_content">
            <h4>1. Account Deletion:</h4>
            <ul>
              <li>{`Open MyAiSquad App > Easily accessible from the profile > Click on hamburger menu from Profile Screen > Scroll down to Delete Account.`}</li>
              <li>Empower users with control over their data.</li>
              <li>Transparent process.</li>
            </ul>
          </div>
          <div className="acc_sec_content">
            <h4>2. Why It Matters:</h4>
            <ul>
              <li>
                User Autonomy: Every user has the right to decide the destiny of
                their data.
              </li>
              <li>
                Trust: Reinforces the trust between Google Play and its users.
              </li>
              <li>
                Data Minimization: Ensures unnecessary data isn't kept
                indefinitely.
              </li>
            </ul>
          </div>
          <div className="acc_sec_content">
            <h4>3. What Happens After Deletion?:</h4>
            <ul>
              <li>All personal data associated with the account is erased.</li>
              <li>Data is no longer accessible or recoverable.</li>
              <li>
                Some anonymized data might be kept for analytical purposes.
              </li>
            </ul>
          </div>
          <div className="acc_sec_content">
            <h4>4. Safety First:</h4>
            <ul>
              <li>Secure protocols in place for the deletion process.</li>
              <li>Ensures no unauthorized deletions.</li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DeleteAccount;
