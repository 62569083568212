import React, { useEffect, useState } from "react";
import "./WidgetLogin.scss";
import loginimg from "../../Assets/Images/loginimg.png";
import logo from "../../Assets/Images/logo.png";
import FormInputs from "../../Components/FormInputs/FormInputs";
import { BsEyeSlash } from "react-icons/bs";
import { BsEye } from "react-icons/bs";
import { UserLogin } from "../../utils/apiCalls";
import { useNavigate } from "react-router-dom";
import Confirm from "../../Components/confirmModal/confirm";
import { ThreeDots } from "react-loader-spinner";
import { EmailIcon, PasswordLock } from "../../Assets/icons/Icons";
import { useTranslation } from "react-i18next";

const initialValues = {
  email: "",
  password: "",
};

const WidgetLogin = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [login, setLogin] = useState(initialValues);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState(initialValues);
  const [showInvalidUser, setShowInvalidUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "english"
  );

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const saveLanguage = (lang) => {
    localStorage.setItem("selectedLanguage", lang);
    setSelectedLanguage(lang === "english" ? "English" : "中文");
    i18n.changeLanguage(lang);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setLogin({
      ...login,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateFields = () => {
    let errObj = { ...initialValues };

    if (!login.email) {
      errObj.email = t("errorOne");
    } else if (!/\S+@\S+\.\S+/.test(login.email)) {
      errObj.email = t("emailError");
    } else {
      errObj.email = "";
    }
    if (!login.password) {
      errObj.password = t("errorOne");
    } else if (login.password.length < 8) {
      errObj.password = t("passwordError");
    } else {
      errObj.password = "";
    }

    setErrors((prev) => ({ ...prev, ...errObj }));
    const data = Object.values(errObj).every((x) => x === "" || x === null);
    return data;
  };

  const handleSubmit = () => {
    setIsLoading(true);
    if (validateFields()) {
      const payload = {
        email: login.email,
        password: login.password,
      };
      UserLogin((res) => {
        const { status, data, message } = res;
        if (status === 200) {
          localStorage.setItem("myaisquadtoken", data.token);
          localStorage.setItem("userData", JSON.stringify(data));
          localStorage.setItem("selectedLanguage", "english");
          navigate("/widget-layout");
          setIsLoading(false);
        } else {
          setShowInvalidUser(true);
          setIsLoading(false);
          setMessages(message);
        }
      }, payload);
    } else {
      setIsLoading(false);
    }
  };

//   const location = useLocation();
//   const searchParams = new URLSearchParams(location.search);
//   const tokenFromUrl = searchParams.get("token");

//   useEffect(() => {
//     console.log("Token from URL:", tokenFromUrl);
//     if (tokenFromUrl) {
//       localStorage.setItem("token", tokenFromUrl);
//       navigate("/role");
//     }
//   }, [tokenFromUrl]);

  useEffect(() => {
    const storedToken = localStorage.getItem("myaisquadtoken");
    if (storedToken) {
      navigate("/widget-layout");
    }
  }, []);

  return (
    <div className="wglogin">
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <ThreeDots color="#D1D1D1" height={80} width={80} radius="9" />
          </div>
        </div>
      )}
      <div className="wglogin_container">
        <div className="wglogin_section">
          <div className="wglogin_section_left">
            <div className="wglogin_section_left_box">
              <div className="wglogin_section_left_box_header">
                <div className="wglogin_section_left_box_header_heading">
                  <img src={logo} alt="logo" />
                  <h3>Sign in using your myaisquad account</h3>
                </div>
              </div>
              <div className="wglogin_section_left_box_content">
                <div className="wglogin_section_left_box_content_inputs">
                  <div className="wglogin_section_left_box_content_inputs_email">
                    <FormInputs
                      title={"Enter Email"}
                      type={"email"}
                      placeholder={"Enter Email"}
                      name="email"
                      value={login.email}
                      onChange={handleChange}
                      icon={true}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit();
                        }
                      }}
                    />
                    <EmailIcon className="emailicon" />
                    <div>
                      {errors.email && (
                        <span className="error">{errors.email}</span>
                      )}
                    </div>
                  </div>
                  <div className="wglogin_section_left_box_content_inputs_password">
                    <FormInputs
                      title={"Enter Password"}
                      type={showPassword ? "text" : "password"}
                      placeholder={"Enter Password"}
                      name="password"
                      value={login.password}
                      onChange={handleChange}
                      icon={true}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit();
                        }
                      }}
                    />
                    <PasswordLock className="passwordicon" />
                    <BsEyeSlash
                      style={{ display: showPassword ? "none" : "block" }}
                      className="eyeslashicon"
                      onClick={togglePasswordVisibility}
                    />
                    <BsEye
                      style={{ display: showPassword ? "block" : "none" }}
                      className="eyeicon"
                      onClick={togglePasswordVisibility}
                    />
                    <div>
                      {errors.password && (
                        <span className="error">{errors.password}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="wglogin_section_left_box_content_button">
                  <button onClick={handleSubmit}>Sign In</button>
                </div>
              </div>
              <div className="chatbot_poweredby">
                <img src={logo} alt="" />
                <p>
                  Powered by{" "}
                  <a href="https://myaisquad.com/" target="_blank">
                    myaisquad.com
                  </a>{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="wglogin_section_right">
            <img src={loginimg} alt="loginimg" />
          </div>
        </div>
      </div>
      {showInvalidUser && (
        <Confirm
          onCancel={() => setShowInvalidUser(false)}
          // onConfirm={() => {
          //   setShowInvalidUser(false);
          //   navigate("/sign-up");
          // }}
          cancelButtonText="OK"
          isCancelRequired={true}
          // buttonText={"Sign Up"}
          confirmTitle={messages}
        />
      )}
    </div>
  );
};

export default WidgetLogin;
