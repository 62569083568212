import React, { useEffect, useState } from "react";
import "./ApiKey.scss";
import Layout from "../../Components/Layout/Layout";
import { GoTrash } from "react-icons/go";
import Confirm from "../../Components/confirmModal/confirm";
import { ThreeDots } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { CreateApiKey, DeleteApiKey, GetApiKey } from "../../utils/apiCalls";
import BasicTooltip from "../../Components/Tooltip/Tooltip";

const ApiKey = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [apiName, setApiName] = useState("");
  const [showInvalidUser, setShowInvalidUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [messages, setMessages] = useState("");
  const [tooltipTitle, setTooltipTitle] = useState("Copy Api Key");
  const [tooltipTitleCurl, setTooltipTitleCurl] = useState("Copy Curl Code");
  const [tooltipTitleSuccess, setTooltipTitleSuccess] =
    useState("Copy Response");
  const [tooltipTitleBadRequest, setTooltipTitleBadRequest] =
    useState("Copy Response");
  const [tooltipTitleUnauthorized, setTooltipTitleUnauthorized] =
    useState("Copy Response");
  const [tooltipTitleInvalid, setTooltipTitleInvalid] =
    useState("Copy Response");
  // const [apiKeyCopied, setApiKeyCopied] = useState(false);
  // const [curlCodeCopied, setCurlCodeCopied] = useState(false);
  const { t, i18n } = useTranslation();

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleApiNameChange = (e) => {
    setApiName(e.target.value);
  };

  const handleCreateApiKey = () => {
    if (rowsData.length > 0) {
      setMessages("You can only create one API key.");
      setShowInvalidUser(true);
      return;
    }
    setIsLoading(true);
    const selectedLanguage =
      localStorage.getItem("selectedLanguage") || "english";
    const payload = {
      apiKeyName: apiName,
      language: selectedLanguage,
    };
    CreateApiKey((res) => {
      const { status, data, message } = res;
      if (status === 200) {
        setShowPopup(false);
        setIsLoading(false);
        setMessages(message);
        fetchGetApiKey();
      } else {
        setShowInvalidUser(true);
        setIsLoading(false);
        setMessages(message);
      }
    }, payload);
  };

  const fetchGetApiKey = () => {
    const payload = {
      language: localStorage.getItem("selectedLanguage") || "english",
    };
    GetApiKey((res) => {
      const { status, data, message } = res;
      if (status === 200 && data) {
        setRowsData(data);
      } else {
        setRowsData([]);
        setMessages(message);
      }
    }, payload);
  };

  useEffect(() => {
    fetchGetApiKey();
  }, [localStorage.getItem("selectedLanguage")]);

  const handleDelete = async (apiId) => {
    const payload = {
      language: localStorage.getItem("selectedLanguage") || "english",
      apiId: apiId,
    };
    DeleteApiKey((res) => {
      const { status, message } = res;
      if (status === 200) {
        setShowInvalidUser(true);
        setMessages(message);
        fetchGetApiKey();
      } else {
        setShowInvalidUser(true);
        setMessages(message);
      }
    }, payload);
  };

  const handleCopyApiKey = (apiKey) => {
    navigator.clipboard
      .writeText(apiKey)
      .then(() => {
        console.log("API key copied to clipboard");
        // setApiKeyCopied(true);
        setTooltipTitle("Copied!");
        setTimeout(() => {
          // setApiKeyCopied(false);
          setTooltipTitle("Copy Api Key");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy API key: ", error);
      });
  };

  const handleCopyCurlCode = () => {
    const curlCode = `curl -X 'POST' \
    'https://multi-api.myaisquad.com/api/v1/textChat/claudeTextChatReponse' \
    -H 'accept: application/json' \
    -H 'Authorization: Bearer your_api_key' \
    -H 'Content-Type: application/json' \
    -d '{
    "question": "Hello"
  }'`;
    navigator.clipboard
      .writeText(curlCode)
      .then(() => {
        console.log("Curl code copied to clipboard");
        setTooltipTitleCurl("Copied!");
        setTimeout(() => {
          setTooltipTitleCurl("Copy Curl Code");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy curl code: ", error);
      });
  };

  const handleCopyResponse = (response) => {
    navigator.clipboard
      .writeText(response)
      .then(() => {
        console.log("Response copied to clipboard");
        setTooltipTitleSuccess("Copied!");
        setTimeout(() => {
          setTooltipTitleSuccess("Copy Response");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy response: ", error);
      });
  };

  const handleCopyBadRequestResponse = (response) => {
    navigator.clipboard
      .writeText(response)
      .then(() => {
        console.log("Response copied to clipboard");
        setTooltipTitleBadRequest("Copied!");
        setTimeout(() => {
          setTooltipTitleBadRequest("Copy Response");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy response: ", error);
      });
  };

  const handleCopyUnauthorizedResponse = (response) => {
    navigator.clipboard
      .writeText(response)
      .then(() => {
        console.log("Response copied to clipboard");
        setTooltipTitleUnauthorized("Copied!");
        setTimeout(() => {
          setTooltipTitleUnauthorized("Copy Response");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy response: ", error);
      });
  };

  const handleCopyInvalidResponse = (response) => {
    navigator.clipboard
      .writeText(response)
      .then(() => {
        console.log("Response copied to clipboard");
        setTooltipTitleInvalid("Copied!");
        setTimeout(() => {
          setTooltipTitleInvalid("Copy Response");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy response: ", error);
      });
  };

  return (
    <Layout>
      <div className="api">
        {isLoading && (
          <div className="loader-container">
            <div className="loader">
              <ThreeDots color="#D1D1D1" height={80} width={80} radius="9" />
            </div>
          </div>
        )}
        <div className="api_cont">
          <div className="api_sec">
            <div className="api_sec_header">
              <h3>{t("apiKeyHeading")}</h3>
              <button onClick={togglePopup}>{t("apiKeyHeadingButton")}</button>
            </div>
            <div className="api_sec_table">
              <table>
                <thead>
                  <tr>
                    <th>{t("tableHeadingOne")}</th>
                    <th>{t("tableHeadingTwo")}</th>
                    <th>{t("tableHeadingThree")}</th>
                    <th>{t("tableHeadingFour")}</th>
                  </tr>
                </thead>
                <tbody>
                  {rowsData.map((rowData, index) => (
                    <tr key={index}>
                      <td>{rowData.apiKeyName}</td>
                      <td>
                        <div className="apikeyrow">
                          {rowData.apiKey}{" "}
                          <BasicTooltip
                            className="copyicon"
                            onClick={() => handleCopyApiKey(rowData.apiKey)}
                            title={tooltipTitle}
                          />
                        </div>
                      </td>
                      <td>
                        {new Date(rowData.createdDate).toLocaleDateString()}
                      </td>
                      <td>
                        <GoTrash
                          className="trash"
                          onClick={() => handleDelete(rowData.apiId)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="api_sec_container">
              <div className="api_sec_container_content">
                <div className="api_sec_container_content_left">
                  <h3>API Curl</h3>
                  <ul>
                    <li>
                      Replace your_api_key with the API key that you have
                      created.
                    </li>
                    <li>
                      In the request body, replace the question with your question.
                    </li>
                    <li>
                      You can use the following curl command to make the API
                      request:
                    </li>
                  </ul>
                </div>
                <div className="api_sec_container_content_right">
                  <div className="api_sec_container_content_right_header">
                    <div className="api_sec_container_content_right_header_left">
                      <h3>Curl</h3>
                    </div>
                    <div className="api_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={handleCopyCurlCode}
                        title={tooltipTitleCurl}
                      />
                    </div>
                  </div>
                  <div className="api_sec_container_content_right_curl">
                    <pre>
                      <code>curl -X 'POST' \</code>
                      <br />
                      <code>
                        'https://multi-api.myaisquad.com/api/v1/textChat/claudeTextChatReponse' \
                      </code>
                      <br />
                      <code>-H 'accept: application/json' \</code>
                      <br />
                      <code>-H 'Authorization: Bearer your_api_key' \</code>
                      <br />
                      <code>-H 'Content-Type: application/json' \</code>
                      <br />
                      <code>-d {`'{`}</code>
                      <code>"question": "Your question here"</code>
                      <code>{`}'`}</code>
                      <br />
                    </pre>
                  </div>
                </div>
              </div>
              <div className="api_sec_container_content">
                <div className="api_sec_container_content_left">
                  <h3>API Responses</h3>
                  <h4>Successful Response</h4>
                  <ul>
                    <li>Sattus Code: '200 OK'</li>
                    <li>Description: The request was successful.</li>
                  </ul>
                </div>
                <div className="api_sec_container_content_right">
                  <div className="api_sec_container_content_right_header">
                    <div className="api_sec_container_content_right_header_left">
                      <h3>Response Body:</h3>
                    </div>
                    <div className="api_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={() =>
                          handleCopyResponse(
                            `{
  "status": 200,
  "message": "Success",
  "data": {
    "question": "Hello",
    "result": "Hello! How can I assist you today?"
  }
}`
                          )
                        }
                        title={tooltipTitleSuccess}
                      />
                    </div>
                  </div>
                  <div className="api_sec_container_content_right_curl">
                    <pre>
                      <code>
                        {`{
  "status": 200,
  "message": "Success",
  "data": {
    "question": "Hello",
    "result": "Hello! How can I assist you today?"
  }
}`}
                      </code>
                    </pre>
                  </div>
                </div>
              </div>
              <div className="api_sec_container_content">
                <div className="api_sec_container_content_left">
                  <h4>Bad Request</h4>
                  <ul>
                    <li>Status Code: 400 Bad Request</li>
                    <li>
                      Description: The request was malformed or missing the required
                      parameters.
                    </li>
                    <li>
                      Condition: If the question is not provided in the body.
                    </li>
                  </ul>
                </div>
                <div className="api_sec_container_content_right">
                  <div className="api_sec_container_content_right_header">
                    <div className="api_sec_container_content_right_header_left">
                      <h3>Response Body:</h3>
                    </div>
                    <div className="api_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={() =>
                          handleCopyBadRequestResponse(
                            `{
  "status": 400,
  "message": "Bad Request",
  "data": null
}`
                          )
                        }
                        title={tooltipTitleBadRequest}
                      />
                    </div>
                  </div>
                  <div className="api_sec_container_content_right_curl">
                    <pre>
                      <code>
                        {`{
  "status": 400,
  "message": "Bad Request",
  "data": null
}`}
                      </code>
                    </pre>
                  </div>
                </div>
              </div>
              <div className="api_sec_container_content">
                <div className="api_sec_container_content_left">
                  <h4>Unauthorized Responses</h4>
                  <ul>
                    <li>Status Code: 401 Unauthorized</li>
                    <li>
                      Description: The request lacked proper authentication.
                    </li>
                    <li>Condition: If the API key is missing or malformed.</li>
                  </ul>
                </div>
                <div className="api_sec_container_content_right">
                  <div className="api_sec_container_content_right_header">
                    <div className="api_sec_container_content_right_header_left">
                      <h3>Response Body:</h3>
                    </div>
                    <div className="api_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={() =>
                          handleCopyUnauthorizedResponse(
                            `{
  "status": 401,
  "message": "Unauthorized: API key missing or malformed",
  "data": null
}`
                          )
                        }
                        title={tooltipTitleUnauthorized}
                      />
                    </div>
                  </div>
                  <div className="api_sec_container_content_right_curl">
                    <pre>
                      <code>
                        {`{
  "status": 401,
  "message": "Unauthorized: API key missing or malformed",
  "data": null
}`}
                      </code>
                    </pre>
                  </div>
                </div>
              </div>
              <div className="api_sec_container_content">
                <div className="api_sec_container_content_left">
                  <ul>
                    <li>Status Code: 401 Unauthorized</li>
                    <li>
                      Description: The request lacked proper authentication.
                    </li>
                    <li>Condition: If the API key is invalid.</li>
                  </ul>
                </div>
                <div className="api_sec_container_content_right">
                  <div className="api_sec_container_content_right_header">
                    <div className="api_sec_container_content_right_header_left">
                      <h3>Response Body:</h3>
                    </div>
                    <div className="api_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={() =>
                          handleCopyInvalidResponse(
                            `{
  "status": 401,
  "message": "Unauthorized: Invalid API key",
  "data": null
}`
                          )
                        }
                        title={tooltipTitleInvalid}
                      />
                    </div>
                  </div>
                  <div className="api_sec_container_content_right_curl">
                    <pre>
                      <code>
                        {`{
  "status": 401,
  "message": "Unauthorized: Invalid API key",
  "data": null
}`}
                      </code>
                    </pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="confirm">
          <div className="confirm_popup">
            <div className="confirm_popup_inner">
              <h2>{t("apiKeyPopupHeading")}</h2>
              <label>{t("apiKeyPopupLabel")}</label>
              <input
                type="text"
                placeholder={t("apiKeyPopupPlaceholder")}
                value={apiName}
                onChange={handleApiNameChange}
              />
              <div className="confirm_popup_buttons">
                <button onClick={togglePopup}>{t("cancelButton")}</button>
                <button onClick={handleCreateApiKey}>
                  {t("apiKeyHeadingButton")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showInvalidUser && (
        <Confirm
          onCancel={() => setShowInvalidUser(false)}
          // onConfirm={() => {
          //   setShowInvalidUser(false);
          //   navigate("/sign-up");
          // }}
          cancelButtonText="OK"
          isCancelRequired={true}
          // buttonText={"Sign Up"}
          confirmTitle={messages}
        />
      )}
    </Layout>
  );
};

export default ApiKey;
