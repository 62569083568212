export const BaseURL = "https://api.myaisquad.com/api/v1/";
export const BaseURL2 =
  "https://ai-assistant.enovcm3c7enr4.us-east-1.cs.amazonlightsail.com/api/v1/";

export const API_CONSTANTS = {
  LOGIN: `${BaseURL}useraccess/login`,
  FORGOT_PASSWORD: `${BaseURL}useraccess/sendResetLinkOTP`,
  RESET_PASSWORD: `${BaseURL}useraccess/resetPassword`,
  VERIFY_USER: `${BaseURL}useraccess/verifyUser`,
  SIGNUP: `${BaseURL}useraccess/signUp`,
  USERROLE: `${BaseURL}profile/getUserRole`,
  UPDATEROLE: `${BaseURL}profile/updateRole`,
  UPLOADFILE: `${BaseURL}profile/uploadFiles`,
  USERDETAILS: `${BaseURL}profile/getUserDetails`,
  USERFILES: `${BaseURL}profile/getUsersFiles`,
  FILEDELETE: `${BaseURL}profile/removeUserFile`,
  GOOGLEAUTH: `${BaseURL}useraccess/google`,
  APPLEAUTH: `${BaseURL}mobileLogin/appleWebLogin`,
  CLEARROLE: `${BaseURL}profile/clearUserRole`,
  UPLOADLINK: `${BaseURL}profile/uploadLink`,
  USERLINKSTATUS: `${BaseURL}profile/getScrapedLinks`,
  CLAUDETEXTCHATRESPONSE: `${BaseURL2}chat/claudeTextChatReponse`,
  CREATEAPIKEY: `${BaseURL}apiKey/createAPIKey`,
  GETAPIKEY: `${BaseURL}apiKey/getAPIKey`,
  DELETEAPIKEY: `${BaseURL}apiKey/deleteAPIKey`,
  CONTACT_US: `${BaseURL}useraccess/contactUs`,
  ADD_ON_PLAN: `${BaseURL}stripe/subscription/addonpaymentsession`,
  SUBSCRIPTION_PLAN: `${BaseURL}stripe/subscription/paymentsession`,
  GET_SUBSCRIPTION_STATUS: `${BaseURL}stripe/subscription/subscriptionStatus`,
  GET_SUBSCRIPTION_DETAILS: `${BaseURL}stripe/subscription/subscriptiondetail`,
};

export const API_METHODS = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  UPDATE: "UPDATE",
  PUT: "PUT",
};
