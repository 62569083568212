/* global webkitSpeechRecognition */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Bars, ThreeDots } from "react-loader-spinner";
import Confirm from "../../Components/confirmModal/confirm";
import MicRecorder from "mic-recorder-to-mp3";
import "./ReactRecorder.scss";
import { VoiceIcon, PauseIcon } from "../../Assets/icons/Icons";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const ReactRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [showInvalidUser, setShowInvalidUser] = useState(false);
  const [recognition, setRecognition] = useState(null); 

  let recordingTimeout;

  const stopRecording = async () => {
    setIsRecording(false);
    setIsLoading(true);

    try {
      const [buffer, blob] = await Mp3Recorder.stop().getMp3();
      const blobURL = URL.createObjectURL(blob);

      const file_upload = new File([blob], "recording.mp3", {
        type: "audio/mpeg",
      });

      const formData = new FormData();
      formData.append("file_upload", file_upload);

      const token = localStorage.getItem("token");
      const response = await axios.post(
        "https://ai-assistant.enovcm3c7enr4.us-east-1.cs.amazonlightsail.com/api/v1/gpt/gptPaidResponse",
        formData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === 200) {
        const audioURL = response.data.data;
        playAudio(audioURL);
      } else {
        console.error("Failed to upload audio");
        setMessages("Failed to upload audio");
      }
    } catch (error) {
      console.error("Error recording audio:", error);
      setMessages("Error recording audio");
    } finally {
      setIsLoading(false);
    }
  };

  const startRecording = () => {
    setIsRecording(true);
    Mp3Recorder.start()
      .then(() => {
        console.log("Recording started");
      })
      .catch((error) => {
        console.error("Error starting recording:", error);
      });

    const recognitionInstance = new webkitSpeechRecognition();
    recognitionInstance.continuous = true;
    recognitionInstance.interimResults = true;
    recognitionInstance.lang = "en-US";

    recognitionInstance.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join("");

      console.log("Transcript:", transcript);

      if (transcript.trim() !== "") {
        clearTimeout(recordingTimeout);
        recordingTimeout = setTimeout(stopRecording, 3000);
      }
    };

    recognitionInstance.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
    };

    recognitionInstance.onend = () => {
      console.log("Speech recognition ended");
      stopRecording();
    };

    setRecognition(recognitionInstance); // Store the recognition instance
    recognitionInstance.start();
  };

  useEffect(() => {
    return () => {
      clearTimeout(recordingTimeout);
      if (recognition) {
        recognition.stop();
      }
    };
  }, []);

  const playAudio = (url) => {
    const cacheBustedURL = `${url}?_=${new Date().getTime()}`;
    const audio = new Audio(cacheBustedURL);
    audio.addEventListener("ended", handleAudioEnded);
    audio
      .play()
      .then(() => {
        setIsPlaying(true);
      })
      .catch((error) => {
        console.error("Error playing audio:", error);
      });
  };

  const handleToggleRecording = () => {
    if (isRecording) {
      setIsRecording(false);
      if (recognition) {
        recognition.stop(); // Stop recognition when recording is toggled off
      }
    } else {
      startRecording();
    }
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
  };

  return (
    <div className="rr">
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <ThreeDots color="#D1D1D1" height={80} width={80} radius="9" />
          </div>
        </div>
      )}
      <div className="rr_container">
        <div className="rr_section">
          <div className="rr_section_center">
            <div className="rr_section_center_box">
              <div className="rr_section_center_box_file">
                <div className="rr_section_center_box_file_choose">
                  <button
                    className={`voice ${isRecording ? "clicked" : ""}`}
                    onClick={handleToggleRecording} // Use handleToggleRecording function
                    disabled={isLoading}
                  >
                    {isRecording ? <PauseIcon /> : <VoiceIcon />}
                  </button>
                  {isPlaying && (
                    <div className="loader-container2">
                      <div className="loader">
                        <Bars color="#4fa94d" height={40} width={50} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="rr_section_center_box_instructions">
                <h4>Steps to use Shameo:</h4>
                <ul>
                  <li>Tap the microphone icon to begin speaking. </li>
                  <li>Once you're done, click the pause icon. </li>
                  <li>
                    The voice assistant will respond and then reset the view to
                    show the microphone button again.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showInvalidUser && (
        <Confirm
          onCancel={() => setShowInvalidUser(false)}
          cancelButtonText="OK"
          isCancelRequired={true}
          confirmTitle={messages}
        />
      )}

      <audio id="apiAudio" controls style={{ display: "none" }} />
    </div>
  );
};

export default ReactRecorder;
