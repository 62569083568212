import React from "react";
import "./PrivacyPolicy.scss";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/sign-up")
  }
  return (
    <div className="pp">
      <div className="pp_container">
        <h3>Privacy Policy</h3>
        <p>
          Please read and understand this privacy policy. This Privacy Policy
          is subject to and governed by the MyAiSquad Terms of Use (“ToU”)..
          This privacy policy only covers the MyAiSquad Sites (as defined in
          the ToU).
        </p>
        <h5>Information Gathered</h5>
        <p>
          We gather two types of data about our users: personal information and
          tracking information.
        </p>
        <h5>Personal Information</h5>
        <p>
          Personal information is provided by users when registering for
          MyAiSquad .io or when signing up for a newsletter.Personal information
          gathered in connection with registration, and subscribing to our
          newsletters may include name, e-mail address and other
          information. Credit and debit card information submitted in connection
          with issuing certificates on blockchain is processed by third-party
          vendors and is not shared with MyAiSquad.
        </p>
        <h5>Tracking Information</h5>
        <p>
          Tracking information is automatically collected about all visitors to
          the MyAiSquad Sites. This information consists of both individual and
          aggregated tracking information and is automatically gathered using
          “cookies.” A cookie is a small data file containing information, such
          as a user's login name, that is written to the user's hard drive by a
          web server and used to track the pages visited. The mobile application
          used in connection with the Consensus and Construct conferences is
          operated by a third party, and such third party may use cookies or
          other tracking technology in connection with operation of the
          application. We use cookies to:
        </p>
        <li>Keep track of advertisements.</li>
        <li>Stop you seeing the same ad over and over again.</li>
        <li>
          Show ads that are more relevant to you, using country and language
          preferences.
        </li>
        <li>
          Compile aggregate data about site traffic and interactions to offer
          better site experiences and tools in future. We may also use trusted
          third-party services that track this information on our behalf.
        </li>
        <li>Enhance site performance and facilitate certain site features.</li>
        <li>
          Understand if you have already taken a required step to view certain
          types of content, such as submitting your email address to post a
          comment.
        </li>
        <p>
          We may also employ technology that is commonly referred to as “action
          tags” (also known as “web beacons,” “scripts” or “one pixel .gif
          files”) to measure the effectiveness of our advertisements.
        </p>
        <p>
          In addition to the cookies described above, MyAiSquad , its agents,
          advertisers and third-party advertising vendors that serve ads onto
          the MyAiSquad Sites, and other third party partners, may use their own
          or third-party cookies or other tracking technologies for advertising,
          including delivering targeted advertisements and marketing messages
          (known as Internet-based advertising or “IBA”) based upon the websites
          you visit, or other purposes. IBA works by showing you advertisements
          that are based on the type of content you access or read. For example,
          as you browse our website, one of the cookies placed on your device
          will be an advertising cookie so we can better understand what sort of
          pages or content you are interested in. The information collected
          about your device enables us to group you with other devices that have
          shown similar interests. We can then display advertising to categories
          of users that is based on common interests. For more information about
          IBA, please visit:
          <a href="http://www.iab.net/public_policy/behavioral-advertisingprinciples">
            http://www.iab.net/public_policy/behavioral-advertisingprinciples
          </a>
          .
        </p>
        <p>
          You can control your browser's settings regarding cookies by selecting
          “Internet Options” or “Preferences” in the menu bar of your browser.
          This will allow you to prevent your browser from accepting new
          cookies, have your browser notify you when you receive a new cookie,
          or disable cookies altogether. However, because cookies allow you to
          more easily navigate web sites, you may prefer to leave them turned
          on. If you are accessing the internet through an alternative method,
          such as a mobile application or third-party content distribution
          service, you understand that you may not have the ability to disable
          cookies, depending on the specific access method.
        </p>
        <p>
          If you want to opt out of receiving interest-based advertising, it
          does not mean that you will no longer receive advertising when you are
          using our website. It just means that we will not use information
          collected about you for IBA and that any advertising you see will not
          be customized or relevant to you. You can exercise your online
          advertising choices at <a href="http://optout.aboutads.info">http://optout.aboutads.info</a> or
          by clicking the AdChoices icon in an ad and following the
          instructions. You may also opt out of receiving interest-based ads
          from many sites through the Network Advertising Initiative's (NAI) Opt
          Out Tool (<a href="http://www.networkadvertising.org/choices">http://www.networkadvertising.org/choices</a>)
           and in the EU at <a href="http://www.youronlinechoices.com/">http://www.youronlinechoices.com/</a>.
          Remember, if you delete cookies, use a different device, or change web
          browsers, you may need to opt out again. Mobile devices have an
          identifier that gives companies the ability to serve targeted ads to a
          specific mobile device. In many cases, you can turn off mobile device
          ad tracking or you can reset the advertising identifier at any time
          within your mobile device privacy settings. Another tool you can use
          to control advertising on your mobile device is the AppChoices App:
          <a href="http://youradchoices.com/appchoices">http://youradchoices.com/appchoices</a>. You may also
          choose to turn off location tracking on your mobile device. If you
          turn off ad tracking or location tracking, we will no longer use
          information collected from your device's advertising identifier for
          the purposes of advertising. You may still see the same number of ads
          but they may be less relevant because they will not be based on your
          interests.
        </p>
        <i>For EU Users:</i>
        <p>
          You can at any time change or withdraw your consent from the Cookie
          Declaration on our website. We use the following types of cookies on
          our website:
        </p>
        <h5>Necessary cookies:</h5>
        <p>
          Necessary cookies help make a website usable by enabling basic
          functions like page navigation and access to secure areas of the
          website. The website cannot function properly without these cookies.
        </p>
        <h5>Preferences:</h5>
        <p>
          Preference cookies enable a website to remember information that
          changes the way the website behaves or looks, like your preferred
          language or the region that you are in.
        </p>
        <h5>Statistics:</h5>
        <p>
          Statistic cookies help website owners to understand how visitors
          interact with websites by collecting and reporting information
          anonymously.
        </p>
        <h5>Marketing</h5>
        <p>
          Marketing cookies are used to track visitors across websites. The
          intention is to display ads that are relevant and engaging for the
          individual user and thereby more valuable for publishers and third
          party advertisers.
        </p>
        <h5>Unclassified</h5>
        <p>
          Unclassified cookies are cookies that we are in the process of
          classifying, together with the providers of individual cookies.
        </p>
        <h5>
          We cannot be responsible for the privacy policies or practices of
          third party advertisers and vendors. Additionally, third party
          advertising vendors, including Google, show our ads on sites on the
          Internet. Use of Information.
        </h5>
        <p>
          MyAiSquad uses the personal information and tracking information
          obtained from its users as stated in this Privacy Policy, and for the
          following purposes:
        </p>

        <li>
          To enhance user's experiences on the MyAiSquad Sites, including the
          display of customized content and advertising;
        </li>
        <li>To improve our content; and</li>
        <li>To ensure the technical functioning of the MyAiSquad Sites.</li>

        <h5>Information Sharing</h5>
        <p>
          MyAiSquad does not sell or disclose personal information about you
          described above to other people or nonaffiliated companies, except to
          provide you with products or services, when we have your permission,
          or under the following circumstances:
        </p>

        <li>
          In response to subpoenas, court orders, or legal process, or otherwise
          to cooperate with law enforcement agencies or state and federal
          regulators;
        </li>
        <li>
          We provide the information on a confidential basis to nonaffiliated
          companies we engage as contractors or agents to perform services for
          us, such as maintaining software or administering payments.
          Information will be shared with such contractors only to the extent
          reasonably necessary for them to perform services on our behalf, and
          pursuant to confidentiality obligations;
        </li>
        <li>
          If we sell or otherwise transfer substantially all of our assets
          related to any web site that we own or operate and any product or
          service that we offer. In that event, we will require such third party
          to honor our then-current Privacy Policy, until the third party
          provides you with notice of changes to our Privacy Policy and permits
          you to exercise any rights you may have under applicable law to limit
          disclosures of information about you;
        </li>
        <li>
          We may disclose information we collect as described above to other
          companies such as direct marketers to perform marketing services on
          our behalf, or to third parties with whom we have joint marketing
          agreements;
        </li>
        <li>
          We disclose personal information gathered in the conference
          registration process (other than credit and debit card information)
          with the sponsors of that conference; and
        </li>
        <li>
          We may share with third parties certain pieces of aggregated,
          non-personal information, such as the number of users who clicked on a
          particular link or how many users clicked on a particular
          advertisement. Such information does not identify you individually.
        </li>

        <h5>Postings</h5>
        <p>
          Postings related to articles on the MyAiSquad Sites are publicly
          available. You should exercise caution when deciding to disclose any
          of your personal information in a public posting.
        </p>
        <i>
          This section shall apply to any personal data collected by MyAiSquad
          from EU residents. If you are an EU resident, MyAiSquad will only
          share your personal data with third parties with your consent.
        </i>
        <h5>How long we retain data</h5>
        <p>
          We retain your personal data for the duration of your business
          relationship with us, and we hold your information only as long as
          necessary for each purpose we use it, unless we have obtained your
          consent to use for another or similar purpose.
        </p>
        <h5>How long we retain data</h5>
        <p>
          If you inform us that you want your personal data erased (if you are
          an EU resident), or that you no longer wish for us to communicate with
          you for marketing purposes, or to otherwise restrict MyAiSquad's
          processing of your personal data, we may retain some basic information
          in order to avoid sending you unwanted materials in the future, and to
          keep a record of your request and our response.
        </p>
        <h5>Contacting MyAiSquad </h5>
        <i>
          This section shall apply to any personal data collected by MyAiSquad
          from EU residents.
        </i>
        <p>
          To confirm that MyAiSquad is processing your personal data, or to
          access, update or correct the personal data MyAiSquad holds about you,
          or to obtain a copy to reuse for your own purposes, or If you want
          your personal data erased, or you want to otherwise restrict MyAiSquad
          's processing of personal data please send an e-mail to @MyAiSquad
          .com Questions or comments regarding this policy should be directed
          to @MyAiSquad.com
        </p>
        <br />
        <br />
        <h4>Updated on February 25, 2024</h4>
        <p>Go back to MyAiSquad.com</p>
        <div className="tc_back">
          <button onClick={handleBack}>Back</button>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
