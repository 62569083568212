/* eslint-disable no-unused-vars */
import { fetchCall, fetchLoginCall, fetchNoCall } from "./ajax";
import { API_CONSTANTS, API_METHODS } from "./api-constant";

export const UserLogin = (callback, payload) => {
  const url = `${API_CONSTANTS.LOGIN}`;
  return fetchCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  );
};

export const UserSignup = (callback, payload) => {
  const url = `${API_CONSTANTS.SIGNUP}`;
  return fetchCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  );
};

export const UserRole = (callback,payload ) => {
  const queryString = `?language=${payload?.language}`
  const url = `${API_CONSTANTS.USERROLE + queryString}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  );
};

export const UpdateRole = (callback, payload) => {
  const url = `${API_CONSTANTS.UPDATEROLE}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.PUT,
    payload
  );
};

export const uploadFile = (callback, payload) => {
  const url = `${API_CONSTANTS.UPLOADFILE}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  );
};

export const UserDetails = (callback, payload) => {
  const url = `${API_CONSTANTS.USERDETAILS}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET,
    payload
  );
};

export const UserFiles = (callback, payload) => {
  const queryString = `?language=${payload?.language}`
  const url = `${API_CONSTANTS.USERFILES + queryString}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET,
    payload
  );
};

export const FileDelete = (callback, payload) => {
  const url = `${API_CONSTANTS.FILEDELETE}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.DELETE,
    payload
  );
};

export const GoogleAuth = (callback) => {
  const url = `${API_CONSTANTS.GOOGLEAUTH}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET,
  );
};

export const AppleAuth = (callback, payload) => {
  const url = `${API_CONSTANTS.APPLEAUTH}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  );
};

export const ClearRole = (callback, payload) => {
  const url = `${API_CONSTANTS.CLEARROLE}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.DELETE,
    payload
  );
};

export const UploadLink = (callback, payload) => {
  const url = `${API_CONSTANTS.UPLOADLINK}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  );
};

export const UserLinkStatus = (callback, payload) => {
  const queryString = `?language=${payload?.language}`
  const url = `${API_CONSTANTS.USERLINKSTATUS + queryString}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET,
    payload
  );
};

export const claudeTextChatResponse = (callback, payload) => {
  const url = `${API_CONSTANTS.CLAUDETEXTCHATRESPONSE}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  );
};

export const CreateApiKey = (callback, payload) => {
  const url = `${API_CONSTANTS.CREATEAPIKEY}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  );
};

export const GetApiKey = (callback, payload) => {
  const queryString = `?language=${payload?.language}`
  const url = `${API_CONSTANTS.GETAPIKEY + queryString}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET,
    payload
  );
};

export const DeleteApiKey = (callback, payload) => {
  const url = `${API_CONSTANTS.DELETEAPIKEY}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.DELETE,
    payload
  );
};

export const ForgotPasswordApi = (callback, payload) => {
  const url = `${API_CONSTANTS.FORGOT_PASSWORD}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  );
};

export const ResetPasswordApi = (callback, payload) => {
  const url = `${API_CONSTANTS.RESET_PASSWORD}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  );
};

export const VerifyUserApi = (callback, payload) => {
  const url = `${API_CONSTANTS.VERIFY_USER}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  );
};

export const ContactUs = (callback, payload) => {
  const url = `${API_CONSTANTS.CONTACT_US}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  );
};

export const AddOnPlan = (callback, payload) => {
  const url = `${API_CONSTANTS.ADD_ON_PLAN}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  );
};

export const SubscriptionPlan = (callback, payload) => {
  const url = `${API_CONSTANTS.SUBSCRIPTION_PLAN}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  );
};

export const GetSubscriptionStatus = (callback, payload) => {
  const url = `${API_CONSTANTS.GET_SUBSCRIPTION_STATUS}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET,
    payload
  );
};

export const GetSubscriptionDetails = (callback, payload) => {
  const url = `${API_CONSTANTS.GET_SUBSCRIPTION_DETAILS}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET,
    payload
  );
};