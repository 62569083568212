import React, { useEffect, useState } from "react";
import "./Login.scss";
import loginimg from "../../Assets/Images/loginimg.png";
import logo from "../../Assets/Images/logo.png";
import FormInputs from "../../Components/FormInputs/FormInputs";
import GoogleLogin from "../../Assets/Images/GoogleLogin.png";
import AppleLogo from "../../Assets/Images/AppleLogo.png";
import { BsEyeSlash } from "react-icons/bs";
import { BsEye } from "react-icons/bs";
import { AppleAuth, GoogleAuth, UserLogin } from "../../utils/apiCalls";
import { useLocation, useNavigate } from "react-router-dom";
import Confirm from "../../Components/confirmModal/confirm";
import { ThreeDots } from "react-loader-spinner";
import { EmailIcon, PasswordLock } from "../../Assets/icons/Icons";
import { useTranslation } from "react-i18next";
import { OAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../Config/firebaseConfig";

const initialValues = {
  email: "",
  password: "",
};

const Login = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [login, setLogin] = useState(initialValues);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState(initialValues);
  const [showInvalidUser, setShowInvalidUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [appleError, setAppleError] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "english"
  );

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const saveLanguage = (lang) => {
    localStorage.setItem("selectedLanguage", lang);
    setSelectedLanguage(lang === "english" ? "English" : "中文");
    i18n.changeLanguage(lang);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setLogin({
      ...login,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateFields = () => {
    let errObj = { ...initialValues };

    if (!login.email) {
      errObj.email = t("errorOne");
    } else if (!/\S+@\S+\.\S+/.test(login.email)) {
      errObj.email = t("emailError");
    } else {
      errObj.email = "";
    }
    if (!login.password) {
      errObj.password = t("errorOne");
    } else if (login.password.length < 8) {
      errObj.password = t("passwordError");
    } else {
      errObj.password = "";
    }

    setErrors((prev) => ({ ...prev, ...errObj }));
    const data = Object.values(errObj).every((x) => x === "" || x === null);
    return data;
  };

  const handleSubmit = () => {
    setIsLoading(true);
    if (validateFields()) {
      const payload = {
        email: login.email,
        password: login.password,
      };
      UserLogin((res) => {
        const { status, data, message } = res;
        if (status === 200) {
          localStorage.setItem("token", data.token);
          localStorage.setItem("userData", JSON.stringify(data));
          localStorage.setItem("selectedLanguage", "english");
          
          navigate("/role");
          setIsLoading(false);
        } else {
          setShowInvalidUser(true);
          setIsLoading(false);
          setMessages(message);
        }
      }, payload);
    } else {
      setIsLoading(false);
    }
  };

  const handleSignUpPage = () => {
    navigate("/sign-up");
  };

  const handleGoogleLogin = () => {
    GoogleAuth((res) => {
      const googleAuthUrl =
        "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?response_type=code&redirect_uri=https%3A%2F%2Fapi.myaisquad.com%2Fapi%2Fv1%2Fuseraccess%2Fgoogle%2Fredirect&scope=email%20profile&client_id=366492047665-geokskmct2lejck3hgfhdpoub7s8cv7a.apps.googleusercontent.com&service=lso&o2v=2&theme=glif&flowName=GeneralOAuthFlow";
      window.open(googleAuthUrl, "_self");
    });
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tokenFromUrl = searchParams.get("token");

  useEffect(() => {
    if (tokenFromUrl) {
      localStorage.setItem("token", tokenFromUrl);
      navigate("/role");
    }
  }, [tokenFromUrl, navigate]);

  useEffect(() => {
    setSelectedLanguage(
      localStorage.getItem("selectedLanguage") === "english"
        ? "English"
        : "中文"
    );
  }, []);

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleAppleLogin = async () => {
    setIsLoading(false);   
    const provider = new OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const identityToken = await result?._tokenResponse?.oauthIdToken;

      const payload = {
        identityToken: identityToken,
        email: user.email,
        familyName: user.displayName,
        givenName: user.displayName,
      };

      AppleAuth((res) => {
        const { status, data, message } = res;
        if (status === 200) {
          localStorage.setItem("token", data.token);
          navigate("/role");
          setIsLoading(false);
        } else {
          setShowInvalidUser(true);
          setIsLoading(false);
          setMessages(message);
        }
      }, payload);
    } catch (error) {
      setAppleError(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("User is signed in:", user);
      } else {
        console.log("No user is signed in");
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <div className="login">
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <ThreeDots color="#D1D1D1" height={80} width={80} radius="9" />
          </div>
        </div>
      )}
      <div className="login_container">
        <div className="login_section">
          <div className="login_section_left">
            <div className="login_section_left_box">
              <div className="login_section_left_box_header">
                <div className="login_section_left_box_header_heading">
                  <img src={logo} alt="logo" />
                  <h3>{t("loginTitle")}</h3>
                </div>
                <div className="login_section_left_box_header_content">
                  <p>{t("loginContent")}</p>
                </div>
              </div>
              <div className="login_section_left_box_content">
                <div className="login_section_left_box_content_inputs">
                  <div className="login_section_left_box_content_inputs_select">
                    <h3>Select Languange</h3>
                    <div
                      className="login_section_left_box_content_inputs_select_dropdown"
                      onClick={toggleDropdown}
                    >
                      <p>{selectedLanguage}</p>
                      <div
                        className={`dropdown-content ${
                          showDropdown ? "show" : ""
                        }`}
                      >
                        <a href="#" onClick={() => saveLanguage("english")}>
                          English
                        </a>
                        <a href="#" onClick={() => saveLanguage("chinese")}>
                          中文
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="login_section_left_box_content_inputs_email">
                    <FormInputs
                      title={t("emailTitle")}
                      type={"email"}
                      placeholder={t("email")}
                      name="email"
                      value={login.email}
                      onChange={handleChange}
                      icon={true}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit();
                        }
                      }}
                    />
                    <EmailIcon className="emailicon" />
                    <div>
                      {errors.email && (
                        <span className="error">{errors.email}</span>
                      )}
                    </div>
                  </div>
                  <div className="login_section_left_box_content_inputs_password">
                    <FormInputs
                      title={t("passwordTitle")}
                      type={showPassword ? "text" : "password"}
                      placeholder={t("password")}
                      name="password"
                      value={login.password}
                      onChange={handleChange}
                      icon={true}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit();
                        }
                      }}
                    />
                    <PasswordLock className="passwordicon" />
                    <BsEyeSlash
                      style={{ display: showPassword ? "none" : "block" }}
                      className="eyeslashicon"
                      onClick={togglePasswordVisibility}
                    />
                    <BsEye
                      style={{ display: showPassword ? "block" : "none" }}
                      className="eyeicon"
                      onClick={togglePasswordVisibility}
                    />
                    <div>
                      {errors.password && (
                        <span className="error">{errors.password}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="login_section_left_box_content_forgot">
                  <h3 onClick={handleForgotPassword}>Forgot Password?</h3>
                </div>
                <div className="login_section_left_box_content_button">
                  <button onClick={handleSubmit}>{t("buttonSignIn")}</button>
                </div>
                <div className="login_section_left_box_content_border">
                  <div className="line"></div>
                  <h3>{t("or")}</h3>
                  <div className="line"></div>
                </div>
                {/* <div
                  className="login_section_left_box_content_appstore"
                  onClick={handleGoogleLogin}
                >
                  <div className="signup_section_right_box_content_appstore_google">
                    <img src={Google} alt="google" />
                  </div>
                  <div className="signup_section_right_box_content_appstore_text">
                    <h3>{t("googleButton")}</h3>
                  </div>
                  
                </div> */}
                <div className="login_section_left_box_content_appstore">
                  <div
                    className="login_section_left_box_content_appstore_google"
                    onClick={handleGoogleLogin}
                  >
                    <img src={GoogleLogin} alt="GoogleLogin" />
                  </div>
                  <div
                    className="login_section_left_box_content_appstore_apple"
                    onClick={handleAppleLogin}
                  >
                    <img src={AppleLogo} alt="apple" />
                  </div>
                </div>
                <div className="login_section_left_box_content_bottom">
                  <p>
                    {t("loginToSignup")}{" "}
                    <span onClick={handleSignUpPage}>{t("signUp")}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="login_section_right">
            <img src={loginimg} alt="loginimg" />
          </div>
        </div>
      </div>
      {showInvalidUser && (
        <Confirm
          onCancel={() => setShowInvalidUser(false)}
          cancelButtonText="OK"
          isCancelRequired={true}
          confirmTitle={messages}
        />
      )}
    </div>
  );
};

export default Login;
