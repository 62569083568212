import React from "react";
import "./TermsAndConditionsContent.scss";
import { useNavigate } from "react-router-dom";

const TermsAndConditionsContent = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/sign-up")
  }
  
  return (
    <div className="tc">
      <div className="tc_container">
      <h3>Terms & Conditions</h3>
      <h4>Acceptance of Terms</h4>
        <li>
          Welcome to MyAiSquad.com. By reading and continuing to use our site you
          (“You” or “Your) agree to the following Terms of Use (“ToU”) and to
          our Privacy Policy. This website is operated by MyAiSquad.com. (“MyAiSquad”,
          “we” “us” “our”), 4 Forest Park Cir. Lake St. Louis, MO 63367. These
          Terms of Use govern, and constitute an Agreement between you and us
          regarding, your use of MyAiSquad.com and all other websites operated by
          us, including websites dedicated to our MyAiSquad.com Inc. and T2M web
          sites, any mobile applications operated or approved by us and any
          other communication tools including e-mail or any other content
          delivery method (collectively, the “MyAiSquad Sites”).
        </li>
        <p>We reserve the right at any time to:</p>
        <li>Change the terms and conditions of the ToU;</li>
        <li>
          Change all or any portion of the MyAiSquad Sites, including eliminating
          or discontinuing any content or feature of the MyAiSquad Sites; or
        </li>
        <li>
          Change other conditions for use of the MyAiSquad Sites including fees or
          other changes for Research (with reasonable notice, in our sole
          discretion).
        </li>
      <p>
        Any changes we make to the ToU will be effective immediately after we
        post the modified ToU on MyAiSquad.com.
      </p>
      <h5>Content on the MyAiSquad Sites</h5>
      <p>
        All of the information and other content displayed on, transmitted
        through, or used in connection with the MyAiSquadSites, including for
        example, advertising, directories, guides, articles, opinions, reviews,
        text, photographs, images, illustrations, audio clips, video, html,
        source and object code, software, data, the selection and arrangement of
        the aforementioned and the “look and feel” of the MyAiSquad Sites
        (collectively, the “Content”), are protected under applicable copyrights
        and other proprietary (including but not limited to intellectual
        property) rights and are the intellectual property of MyAiSquad, and its
        affiliated companies, licensors and suppliers. MyAiSquad actively protects
        its rights to the Content to the fullest extent of the law. You may use
        the Content online and solely for your personal, non-commercial use, and
        you may download or print a single copy of any portion of the Content
        for your personal, non-commercial use, provided you do not remove any
        trademark, copyright or other notice contained in such Content. You may
        not, for example, republish the Content on any Internet, Intranet or
        Extranet site or incorporate the Content in any database, compilation,
        archive or cache or store the Content in electronic form on your
        computer or mobile device unless otherwise expressly permitted by
        MyAiSquad. You may not distribute any of the Content to others, whether or
        not for payment or other consideration, and you may not modify, copy,
        frame, reproduce, sell, publish, transmit, display or otherwise use any
        portion of the Content, except as permitted by the ToU or by securing
        the prior written consent of MyAiSquad. The Content includes logotypes,
        trademarks and service marks (collectively “Marks”) owned by MyAiSquad, and
        Marks owned by other information providers and third parties. For
        example, “MyAiSquad” is a registered trademark of MyAiSquad.com Inc. No Marks
        may be used in any manner unless approved in advance, in writing by
        MyAiSquad. Requests to use the Content for any purpose other than as
        permitted in the ToU should be submitted to MyAiSquad.com.
      </p>
      <h5>Infringement Complaints</h5>
      <p>
        MyAiSquad respects the intellectual property of others. If you believe that
        your work has been copied in a way that constitutes copyright
        infringement or are aware of any infringing material placed by any third
        party on the Website, please contact our designated copyright agent, in
        writing, either by email at MyAiSquad.comor by First Class mail at
        MyAiSquad.com Inc. 4 Forest Park Cir. Lake St. Louis, MO 63367, Attn:
        Copyright Agent, and provide the following information as required by
        the Online Copyright Infringement Liability Limitation Act of the
        Digital Millennium Copyright Act, 17 U.S.C. Section 512(c)(3):
      </p>
      <li>
        A physical or electronic signature of a person authorized to act on
        behalf of the owner of an exclusive right that is allegedly infringed;
      </li>
      <li>
        Identification of the copyright work claimed to have been infringed;
      </li>
      <li>
        Identification of the material that is claimed to be infringing or to
        be the subject of infringing activity and that is to be removed or
        access to which is to be disabled, and information reasonably sufficient
        to locate the material;
      </li>
      <li>
        Information sufficient to permit us to contact the complaining party,
        including address, telephone number, and e-mail address;
      </li>
      <li>
        A statement that the complaining party has a good-faith belief that use
        of the material in the manner complained of is not authorized by the
        copyright owner, its agent, or the law; and
      </li>
      <li>
        A statement that the information in the notification is accurate, and
        under penalty of perjury, that the complaining party is authorized to
        act on behalf of the owner of an exclusive right that is allegedly
        infringed.
      </li>
      <h5>
        Disclaimer of Warranty and Limitation of Liability The information,
        products and services on the MyAiSquad Sites are provided on a strictly “as
        is,” “where is” and “where available” basis. MyAiSquad does not provide any
        warranties (either express or implied) with respect to the information
        provided on any MyAiSquad site and/or your use of any of the MyAiSquad Sites
        generally or for any particular purpose. MyAiSquad expressly disclaims any
        implied warranties, including but not limited to, warranties of title,
        non-infringement, merchantability or fitness for a particular purpose.
        MyAiSquad will not be responsible for any loss or damage that could result
        from interception by third parties of any information made available to
        you via the MyAiSquad Sites or any of them. Although the information
        provided to you on this website is obtained or compiled from sources we
        believe to be reliable, MyAiSquad cannot and does not guarantee the
        accuracy, validity, timeliness, or completeness of any information or
        data made available to you for any particular purpose. Neither MyAiSquad,
        nor any of its affiliates, directors, officers or employees, nor any
        third party providers of content, software and/or technology
        (collectively, the “MyAiSquad parties”), will be liable or have any
        responsibility of any kind for any loss or damage that you incur in the
        event of any failure or interruption of any MyAiSquad site, or resulting
        from the act or omission of any other party involved in making any
        MyAiSquad site, the data contained therein or the products or services
        offered thereby available to you, or from any other cause relating to
        your access to, inability to access, or use of any MyAiSquad site or the
        materials contained therein, whether or not the circumstances giving
        rise to such cause may have been within the control of MyAiSquad or of any
        vendor providing software or services. In no event will MyAiSquad or any of
        the MyAiSquad parties be liable to you, whether in contract or tort, for
        any direct, special, indirect, consequential or incidental damages or
        any other damages of any kind even if MyAiSquad or any other such party has
        been advised of the possibility thereof. This limitation on liability
        includes, but is not limited to, the transmission of any viruses which
        may infect a user's equipment, failure of mechanical or electronic
        equipment or communication lines, telephone or other interconnect
        problems (e.g., you cannot access your internet service provider),
        unauthorized access, theft, operator errors, strikes or other labor
        problems or any force majeure. MyAiSquad cannot and does not guarantee
        continuous, uninterrupted or secure access to any of the MyAiSquad Sites.
      </h5>
      <h5>Disclaimer</h5>
      <p>
        All writers opinions are their own and do not constitute financial
        advice in any way whatsoever. Nothing published by MyAiSquad constitutes an
        investment recommendation, nor should any data or Content published by
        MyAiSquad be relied upon for any investment activities. MyAiSquad strongly
        recommends that you perform your own independent research and/or speak
        with a qualified investment professional before making any financial
        decisions.
      </p>
      <h5>Links to other sites</h5>
      <p>
        Certain links, including hypertext links, in our site will take you to
        external websites. These are provided for your convenience and inclusion
        of any link does not imply endorsement or approval by MyAiSquad of the
        linked site, its operator or its content. Each of those websites have
        their own “Terms and Conditions.” We are not responsible for the content
        of any website outside of the MyAiSquad Sites. We do not monitor and assume
        no duty to monitor the content of any such third-party websites.
      </p>
      <h5>Use of cookies</h5>
      <p>
        Cookies are small text files that are placed on your computer by
        websites that you visit. They are widely used in order to make websites
        work, or work more efficiently, as well as to provide information to the
        owners of the site. These cookies are used to collect information about
        how visitors use our site. We use the information to compile reports and
        to help us improve the site. The cookies collect information in an
        anonymous form, including the number of visitors to the site, where
        visitors have come to the site from and the pages they visited. By using
        our website, you agree that we can place these types of cookies on your
        device. CHOICE OF LAW The ToU and the agreement formed hereby
        (“Agreement”) shall be governed by, and construed and enforced in
        accordance with, the laws of the State of New Mexico, without regard to
        conflicts of laws provisions. Unless otherwise agreed in writing by you
        and us, any dispute arising out of or relating to the Agreement, or the
        breach hereof, shall be finally resolved by arbitration administered by
        the American Arbitration Association under its Commercial Arbitration
        Rules, or such arbitration body as required by law, rule or regulation,
        and judgment upon the award rendered by the arbitrator may be entered in
        any court having jurisdiction. The arbitration will be conducted in the
        English language before a single arbitrator in the City of Albuquerque,
        New Mexico. Such arbitration must be commenced within one (1) year after
        the claim or cause of action arises. If for any reason any provision of
        this Agreement, or a portion thereof, shall be unenforceable, that
        provision shall be enforced to the maximum extent permissible so as to
        effect the intent of this Agreement, and the remainder of this Agreement
        shall continue in full force and effect. This Agreement constitutes the
        entire agreement between us and you with respect to the MyAiSquad Sites and
        it supersedes all prior or contemporaneous communications, agreements
        and understandings between us and you with respect to the subject matter
        hereof. A printed version of this Agreement shall be admissible in
        judicial or administrative proceedings.
      </p>
      <h5>Contact</h5>
      <p>@MyAiSquad.com</p>
      <p>Any rights not expressly granted herein are reserved.</p>
      <p>Copyright © 2021 MyAiSquad.com</p>
      <br />
      <br />
      <h4>Updated on Feb 24, 2024</h4>
      <p>Go back to MyAiSquad.com</p>
      <div className="tc_back">
      <button onClick={handleBack}>Back</button>
      </div>
    </div>
    </div>
  );
};

export default TermsAndConditionsContent;
