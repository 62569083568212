import React from 'react';
import "./Dashboard.scss";

const Dashboard = () => {
  return (
    <div className='dashboard'>
        <div className="dashboard_container">
            <div className="dashboard_section">
                <div className="dashboard_section_center">
                    <h1>Coming Soon ...</h1>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Dashboard;
