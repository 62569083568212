import React, { createContext, useState, useEffect } from "react";
import { UserDetails } from "../utils/apiCalls";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [isLoginUserDetails, setIsLoginUserDetails] = useState([]);

  const getLoginUserDetails = () => {
    const payload = {
      token: localStorage.getItem("token"),
    };
    if (localStorage.getItem("token")) {
      UserDetails((res) => {
        if (res?.status === 200) {
          setIsLoginUserDetails(res?.data);
        } else if (res === 400){
          console.error("Error:", "Subscription Required");
        }
      }, payload);
    }
  };

  useEffect(() => {
    getLoginUserDetails();
  }, []);

  const updateLoginUserDetails = (newData) => {
    setIsLoginUserDetails((prevData) => ({ ...prevData, ...newData }));
  };

  const clearLoginUserDetails = () => {
    setIsLoginUserDetails([]);
  };

  return (
    <UserContext.Provider
      value={{
        isLoginUserDetails,
        updateLoginUserDetails,
        clearLoginUserDetails,
        getLoginUserDetails,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
